import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom' ,
    },
    title: {
      display: true,
      text: 'Overall Production Rate',
      font: {
        size: 24
      },
    },
  },
};

const labels = ['Week1', 'Week2', 'Week3', 'Week4'];

export const data = {
  labels,
  datasets: [
    
  ],
};

export function ChartLine() {
  return <Line options={options} data={data} />;
}

