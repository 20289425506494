import React, { useState, useEffect } from 'react';
import '../../config/config.js';
import Header from '../../components/Header.js'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Col, Row } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2'
import Forbidden from '../../components/Forbidden.js';
import { FwTypeOptions } from '../../config/config';


const LinesForm = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true); // 初始状态为 true，表示正在加载数据
    const [errors, setErrors] = useState('');
    const [productsOptions, setProductsOptions] = useState([]);
    const [factoriesOptions, setFactoriesOptions] = useState([]);
    const [fwVersionOptions, setFwVersionOptions] = useState([]);

    const [formData, setFormData] = useState({
        name: '',
        factory: '',
        subcontractor: '',
        description: '',
        fw_versions: [],
        id: id
    });
    const handleSelectChange = (selectedOption, actionMeta) => {
        // 检查 actionMeta 是否存在
        if (actionMeta) {
            const { name } = actionMeta || {};
            if (name) {
                // 更新 formData 中的值
                setFormData({
                    ...formData,
                    [name]: selectedOption ? selectedOption.value : ''  // 单选框处理
                });
            }
        } else {
            console.error('没有提供 actionMeta 或 selectedOption。');
        }
    };
    const handleChange = (e, actionMeta) => {
        const { name, value, options, type } = e.target || {};
        ////console.log(type);
        // 处理多选下拉菜单
        if (actionMeta && actionMeta.action === 'select-multi') {
            setFormData({
                ...formData,
                [actionMeta.name]: actionMeta.selectedOptions ? actionMeta.selectedOptions.map(option => option.value) : [],
            });

            // 处理单选按钮
        } else if (type === 'radio') {
            setFormData({ ...formData, [name]: value });


        } else if (type === 'select-one') {
            setFormData({
                ...formData,
                [name]: value
            });
            // 处理复选框
        } else if (type === 'text' || type === 'email' || type === 'number' || type === 'password') {
            setFormData({ ...formData, [name]: value });

            // 处理选择框
        } else if (type === 'select-multiple') {
            setFormData({
                ...formData,
                [name]: Array.from(options).filter(option => option.selected).map(option => option.value),
            });
            // 處理 textarea
        } else if (e.target && e.target.tagName === 'TEXTAREA') {
            setFormData({ ...formData, [name]: value });
        }
    };



    const handleMultiSelectChange = (selectedOptions) => {
        setFormData({
            ...formData,
            ['fw_versions']: selectedOptions ? selectedOptions.map(option => option.value) : [],
        });

       //console.log(selectedOptions);
    };

    useEffect(() => {
        if (errors) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: errors,
            });
        }
    }, [errors]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const headers = {
            'Authorization': `Bearer ${token}`, // 替換為你的 token
            'Content-Type': 'application/json',
        };

        // 使用 Promise.all 处理多个请求
        Promise.all([
            axios.get(`${window.api_url}/products`, { headers }),  // 第一个请求
            axios.get(`${window.api_url}/factories`, { headers }),  // 第一个请求
            axios.get(`${window.api_url}/firmware/versions`, { headers }),  // 第一个请求
        ])
            .then(([productResponse, factoriesResponse, fwVersionResponse]) => {
                if (productResponse.data.code === "0000") {
                    const productsOption = productResponse.data.data.map(product => ({
                        value: product.id,
                        label: product.name,
                    }));
                    setProductsOptions(productsOption);
                }
                if (factoriesResponse.data.code === "0000") {
                    const factoriesOption = factoriesResponse.data.data.content.map(factory => ({
                        value: factory.id,
                        label: factory.name,
                    }));
                    setFactoriesOptions(factoriesOption);
                }
                if (fwVersionResponse.data.code === "0000") {
                    const fwVersionOptions = fwVersionResponse.data.data.content.map(fwVersion => ({
                        value: fwVersion.id,
                        label: `${fwVersion.name}(${FwTypeOptions[fwVersion.type]?FwTypeOptions[fwVersion.type]:""})` ,
                    }));
                    setFwVersionOptions(fwVersionOptions);
                }


                setLoading(false); // 两个请求都完成后，更新 loading 状态为 false
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // 如果是 401 错误，重定向到登录页面
                    window.location.href = '/login';  // 直接使用相对路径 '/login'
                } else {
                    console.error("獲取數據時出錯了！", error);
                }
                console.error("獲取數據時出錯了！", error);
                setLoading(false); // 请求出错，更新 loading 状态为 false
            });
        if (id) {
            axios.get(`${window.api_url}/lines/${id}`, { headers, })
                .then((response) => {

                    if (response.data.code === "0000") {
                        // if (response.data.data.factory) {
                        //     response.data.data.factory = response.data.data.factory.id;
                        // }
                        if (response.data.data.product) {
                            response.data.data.product = response.data.data.product.id;
                        }

                        response.data.data.fw_versions = [];
                        if (response.data.data.line_fw_version_link != null) {
                            response.data.data.line_fw_version_link.map((item) => {
                                response.data.data.fw_versions.push(item.fw_version.id)
                                return item;
                            })
                        }
                        setFormData(response.data.data);
                    }

                    setLoading(false); // 两个请求都完成后，更新 loading 状态为 false
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        // 如果是 401 错误，重定向到登录页面
                        window.location.href = '/login';  // 直接使用相对路径 '/login'
                    } else {
                        console.error("獲取數據時出錯了！", error);
                    }
                    console.error("获取数据时出错了！", error);
                    setLoading(false); // 请求出错，更新 loading 状态为 false
                });
        } else {

        }
    }, [id]); // 空依赖项数组表示这个 useEffect 只会运行一次


    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.name === "") {
            setErrors('Name is Required');
        }
        else {
            setErrors('');


            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Bearer ${token}`, // 替換為你的 token
                'Content-Type': 'application/x-www-form-urlencoded',
            };

            if (id) {

                axios.put(`${window.api_url}/lines/${id}`, formData, { headers },)
                    .then(response => {
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            window.location.href = "/lines";
                        });
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            // 如果是 401 错误，重定向到登录页面
                            window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                        } else {
                            console.error("獲取數據時出錯了！", error);
                        }
                    });
            } else {

                axios.post(`${window.api_url}/lines`, formData, { headers })
                    .then(response => {
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            window.location.href = "/lines";
                        });
                    })
                    .catch(error => {
                        // 處理錯誤
                        if (error.response && error.response.status === 401) {
                            // 如果是 401 错误，重定向到登录页面
                            window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                        } else {
                            console.error("獲取數據時出錯了！", error);
                        }
                    });
            }
        }
    };

    if (loading) {
        return Swal.showLoading(); // 显示加载状态
    }
    Swal.close()
    if(window.userRole!=="1"){
        return (<><Forbidden></Forbidden></>);
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <title>Line Form</title>
                </Helmet>
            </HelmetProvider>
            <Header />

            <Container style={{ 'backgroundColor': 'white', "padding": "30px" }} className="mt-5">
                <Row>
                    <h4 className="mt-3">Line Form</h4>
                </Row>

                <Form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" disabled  value={formData.name || ''}  />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            Product
                        </Form.Label>
                        <Col sm="10">
                            <Select
                                name='product'
                                options={productsOptions}
                                value={productsOptions.find(option => option.value === formData.product)} // 设置选中的值
                                onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'product' })}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            Factory
                        </Form.Label>
                        <Col sm="10">
                            {/* <Select
                                disabled
                                options={factoriesOptions}
                                value={factoriesOptions.find(option => option.value === formData.factory)} // 设置选中的值
                                onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'factory' })}
                            /> */}
                            <Col sm="10">
                            <Form.Control type="text" disabled value={formData.factory.name || ''}   />
                        </Col>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Description
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control
                                as="textarea"
                                name='description'
                                value={formData.description || ''}
                                placeholder="description"
                                onChange={handleChange}
                                rows={3} // 可以设置行数
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            FW version
                        </Form.Label>
                        <Col sm="10">
                            <Select
                                isMulti
                                options={fwVersionOptions}
                                onChange={handleMultiSelectChange}
                                value={fwVersionOptions.filter(option => formData.fw_versions?.includes(option.value))}  // 使用 optional chaining
                            />
                        </Col>
                    </Form.Group>
                    {errors && (
                        <Row>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <span style={{ color: 'red', fontSize: '12px' }}>{errors}</span>
                            </Col>
                        </Row>
                    )}
                    <div style={{ "textAlign": "right" }}>
                        <Button type="submit">Submit</Button>
                    </div>

                </Form>
            </Container>
        </>
    );

}

export default LinesForm