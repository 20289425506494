import React, { useState, useEffect } from "react";
import '../../config/config.js';
import Header from '../../components/Header.js'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import UserTable from '../../components/UserTable.js';
import { Row } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import axios from "axios";
import Swal from "sweetalert2";
import Forbidden from "../../components/Forbidden.js";

// const data = [
//     {
//         site: 'FTCH 1',
//         type: 'Line Manager',
//         name: 'Travis',
//         eamil: 'travis@gmail.com',
//         phone: '+886 910123456',
//         status: <Badge bg="success">Actice</Badge>,
//         action:
//             <>
//                 <a href="/users/1/edit">Edit</a> | <a href="/users/reset_password">Reset Password</a>,
//             </>

//     }
// ];

const token = localStorage.getItem('token');
const headers = {
    'Authorization': `Bearer ${token}`, // 替換為你的 token
    'Content-Type': 'application/json',
};

const onClickDelete = (e) => {

    Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
    }).then((result) => {
        if (result.isConfirmed) {

            axios.delete(`${window.api_url}/users/${e.target.getAttribute("uid")}`, { headers, })
                .then((response) => {
                   //console.log(response)
                    if (response.data.code === "0000") {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success"
                        });
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        // 如果是 401 错误，重定向到登录页面
                        window.location.href = '/login';  // 直接使用相对路径 '/login'
                    } else {
                        console.error("獲取數據時出錯了！", error);
                    }
                });

        }
    });

}


const List = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        // 發送帶有 headers 的請求
        axios.get(`${window.api_url}/users`, { headers, })
            .then((response) => {
               //console.log(response)
                if (response.data.code === "0000") {

                    if (response.data.data != null && response.data.data.length > 0) {
                        var rows = response.data.data;
                       //console.log(rows);
                        rows.map((row) => {
                            row.status_o = row.status;
                            row.statusValue = row.status === 1 ? 'Enable' : 'Disable';
                            row.action = <>
                                <a className="btn btn-sm btn-link" href={`/users/${row.id}/edit`}>Edit</a>  |
                                <a className="btn btn-sm btn-link" onClick={onClickDelete} uid={row.id} >Delete</a></>
                            let sites = "";

                            switch (row.role.id) {
                                case 1:
                                    row.site = "All"
                                    break;
                                case 2:

                                if (row.user_factory_links != null && row.user_factory_links.length > 0) {
                                    sites = row.user_factory_links.map((user_factory) => {
                                        return user_factory.factory.name;
                                    })
                                    row.site = sites.join(',');
                                } else {
                                    row.site = "-"
                                }
                                    break;
                                case 3:
                                case 4:
                                if (row.user_line_links != null && row.user_line_links.length > 0) {
                                   //console.log(row.user_line_links)
                                    sites = row.user_line_links.map((user_line) => {
                                        return user_line.line.factory.name;
                                    })
                                   //console.log(sites)
                                    row.site = sites.join(',');
                                } else {
                                    row.site = "-"
                                }
                                    break;
                            }
                            return null;
                        })

                        setData(rows);
                    }

                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // 如果是 401 错误，重定向到登录页面
                    window.location.href = '/login';  // 直接使用相对路径 '/login'
                } else {
                    console.error("獲取數據時出錯了！", error);
                }
            });

    }, []);

    if (window.userRole > 3) {
        return (<><Forbidden></Forbidden></>);
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <title>Users Table</title>
                </Helmet>
            </HelmetProvider>
            <Header />

            <Row>
                <h4>Users Table</h4>
            </Row>
            <UserTable data={data} />

        </>
    );

}


export default List