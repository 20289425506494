import React, { useState, useEffect, useRef } from "react";
import '../config/config.js';
import Header from '../components/Header.js'
import { Button, Nav, Col, Tab, Row } from "react-bootstrap";
import { FaFileExcel, FaCloudDownloadAlt } from "react-icons/fa";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PoTable from '../components/PoTable.js';
import axios from 'axios';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import queryString from "query-string";
import Swal from "sweetalert2";
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv, faSearch } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const Home = () => {

    const parsedHash = queryString.parse(window.location.search);
    const [factoriesBtn, setfactoriesBtn] = useState([]);
    const [loading, setLoading] = useState(true); // 初始状态为 true，表示正在加载数据
    const [poData, setPosData] = useState([]);
    const [selectedPoData, setSelectedPoData] = useState();
    const abortControllerRef1 = useRef(null); // 用于存储当前的 AbortController 实例
    const abortControllerRef2 = useRef(null); // 用于存储当前的 AbortController 实例
    const abortControllerRef3 = useRef(null); // 用于存储当前的 AbortController 实例
    const abortControllerRefPO = useRef(null); // 用于存储当前的 AbortController 实例

    const [po, setPo] = useState(0);
    const [factory, setFactory] = useState(parsedHash.factory || 'all');
    const [station, setStation] = useState({
        board: [],
        qc: [],

    });
    const [ef, setEf] = useState({
        "board": 500,
        "assembly": 500,
        "qc": 500,
    });

    const [token, setToken] = useState(localStorage.getItem('token'));
    const [headers, setHeader] = useState({
        'Authorization': `Bearer ${token}`, // 替換為你的 token
        'Content-Type': 'application/json',
    });

    const [boardData, setBoardData] = useState([]);
    const [boardDataHeaders] = useState([
        { label: 'Serial Number', key: 'serial_number' },
        { label: 'Tap', key: 'tap' },
        { label: 'Subcontractor', key: 'subcontractor' },
        { label: "factory", key: 'factory' },
        { label: "station", key: 'station' },
        { label: "ps", key: 'ps' },
        { label: "mcu flash", key: 'mcu_flash' },
        { label: "esp_flash", key: 'esp_flash' },
        { label: "radar", key: 'radar' },
        { label: "config", key: 'config' },
        { label: "wifi", key: 'wifi' },
    ]);


    const [qcData, setBQcData] = useState([]);
    const [qcDataHeaders] = useState([
        { label: 'Serial Number', key: 'serial_number' },
        { label: 'Tap', key: 'tap' },
        { label: 'Subcontractor', key: 'subcontractor' },
        { label: "factory", key: 'factory' },
        { label: "station", key: 'station' },
        { label: "fw", key: 'fw' },
        { label: "sn", key: 'sn' },
        { label: "usb", key: 'usb' },
        { label: "lens check", key: 'lens_check' },
        { label: "wifi", key: 'wifi' },
    ]);

    const [assemblyData, setAssemblyData] = useState([]);
    const [assemblyDataHeaders] = useState([
        { label: 'Serial Number', key: 'serial_number' },
        { label: 'Subcontractor', key: 'subcontractor' },
        { label: "factory", key: 'factory' },
        { label: "time", key: 'time' },
    ]);

    const [packedData, setPackedData] = useState([]);
    const [packedDataHeaders] = useState([

        { label: 'Serial Number', key: 'serial_number' },
        { label: 'Subcontractor', key: 'subcontractor' },
        { label: "Factory", key: 'factory' },
        { label: "Device Packed", key: 'device_packed' },
        { label: "Box Ready", key: 'box' },
        { label: "Carton Ready", key: 'carton' },
        { label: "Shipped", key: 'shipped' },

    ]);

    const [interval, setMyInterval] = useState(null);


    const [data, setData] = useState({
        "qc": {
            "All": {
                "wip": "-",
                "passed": "-",
                "failed": "-",
                "performance": "-", // 这个值如何计算请自行定义
                "yield": "-"
            }
        },
        "board": {
            "All": {
                "wip": "-",
                "passed": "-",
                "failed": "-",
                "performance": "-", // 这个值如何计算请自行定义
                "yield": "-"
            }
        },
        "assembly": {
            "All": {
                "wip": "-",
                "passed": "-",
                "failed": "-",
                "performance": "-", // 这个值如何计算请自行定义
                "yield": "-"
            }
        },
        "packed": {
            "All": {
                "total": "0",
                "device_packed": "0",
                "box_ready": "0",
                "carton_ready": "0", // 这个值如何计算请自行定义
                "Progress": "0"
            }
        }
    });

    const loadPoResponse = async (response) => {
        if (response.data.code === "0000") {
            if (response.data.data !== null) {
                var index = 0;

                response.data.data.map(item => {
                    if (index === 0) {
                        item.isSelected = true;
                    }
                    index++;
                    if (item.started_at) {
                        const date = new Date(item.started_at);
                        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                        item.started_at = date.toLocaleDateString(undefined, options);
                    } else {
                        item.finished_at = '--';
                    }

                    if (item.finished_at) {
                        const date = new Date(item.finished_at);
                        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                        item.finished_at = date.toLocaleDateString(undefined, options);
                    } else {
                        item.finished_at = '--';
                    }
                    item.ready = 0;
                    item.shipped = 0;
                    item.progress = 0;
                    item.passed = item.passed_count;
                    item.failed = item.failed_count;
                    item.total_in_po = 0;
                    if (item.test_progress_statuses != null) {
                        item.test_progress_statuses.map(tp => {
                            item.progress++;
                            if (tp === 6) {
                                item.ready += 1;
                            }
                            if (tp === 7) {
                                item.shipped += 1;
                            }

                            if (tp > 0) {
                                item.total_in_po += 1;
                            }
                            return tp;
                        })
                    }
                    item.progress = item.progress - item.shipped - item.failed_count;
                    if (item.failed + item.passed > 0) {
                        item.yield = (item.passed / (item.failed + item.passed) * 100).toFixed(2) + "%";
                    } else {
                        item.yield = '-'
                    }
                    return item;
                });
                if (response.data.data.length > 0) {
                    const selectedPo = response.data.data[0];
                    setSelectedPoData(selectedPo);
                    await setPo(selectedPo.id);
                } else {
                    // console.log("getBoardData 0")
                    getBoardData(0);
                    setPo(0)
                }

                setPosData(response.data.data)

                setLoading(false); // 两个请求都完成后，更新 loading 状态为 false
            }
        }
    };

    // useEffect(() => {
    //     ////console.log(selectedPoData)
    //     if (selectedPoData) {
    //         setSelectedPoData(selectedPoData);
    //         // getBoardData(selectedPoData.id);
    //         console.log("selectedPoData action")
    //     }
    // }, [selectedPoData]);
    const handleChange = (e, actionMeta) => {
        const { name, value, type } = e.target || {};
        if (type === 'text' || type === 'number') {
            setEf({ ...ef, [name]: value });
        }
    };

    const handleOnClick = (type) => {
        const formData = {
            factory: factory,
            station_type: type,
            ef: document.getElementById(type).value
        };
        const headers = {
            'Authorization': `Bearer ${token}`, // 替換為你的 token
            'Content-Type': 'application/x-www-form-urlencoded',
        };

        if (factory == "all") {
            getBoardData(po)
            // console.log("factory all action")
        }
        else {
            axios.post(`${window.api_url}/ef/update-or-create`, formData, { headers })
                .then(response => {
                    if (response.data.code === "0000") {
                        Swal.fire("Success!", "", "success")
                        getBoardData(po)
                        // console.log("update-or-create action")
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        // 如果是 401 错误，重定向到登录页面
                        window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                    } else {
                        //console.log(error.response.data.message);
                        Swal.fire("Oh!", error.response.data.message, "error");
                    }
                });
        }
    };

    useEffect(() => {

        setToken(localStorage.getItem('token'));
        setHeader({
            'Authorization': `Bearer ${token}`, // 替換為你的 token
            'Content-Type': 'application/json',
        });
        if (token == null) {
            window.location.href = 'login';
            return
        }
        const params = {
            fid: factory

        };
        // 發送帶有 headers 的請求
        axios.get(`${window.api_url}/factories`, { headers, params })
            .then(response => {
                if (response.data.code === "0000") {
                    setfactoriesBtn(response.data.data.content);
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // 如果是 401 错误，重定向到登录页面
                    window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                } else {
                    console.error("獲取數據時出錯了！", error);
                }
            });



        // 發送帶有 headers 的請求
        axios.get(`${window.api_url}/pos`, { headers, })
            .then(loadPoResponse)
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // 如果是 401 错误，重定向到登录页面
                    window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                } else {
                    console.error("獲取數據時出錯了！", error);
                }
            });



        if (abortControllerRef1.current) {
            abortControllerRef1.current.abort();
        }

        if (abortControllerRef2.current) {
            abortControllerRef2.current.abort();
        }

        if (abortControllerRef3.current) {
            abortControllerRef3.current.abort();
        }
        if (abortControllerRefPO.current) {
            abortControllerRefPO.current.abort();
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            // const tid = await toast.loading("Please wait...")
            await getBoardData(po);
            // 發送帶有 headers 的請求


            if (abortControllerRefPO.current) abortControllerRefPO.current.abort();
            const controllerPO = new AbortController();
            abortControllerRefPO.current = controllerPO;

            axios.get(`${window.api_url}/pos`, { headers, signal: controllerPO.signal })
                .then(loadPoResponse)
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        // 如果是 401 错误，重定向到登录页面
                        window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                    } else {
                        console.error("獲取數據時出錯了！", error);
                    }
                });
            // console.log(useEffect)
            // await toast.update(tid, { render: "Data updated", type: "success", isLoading: false ,autoClose: 3000, position: "bottom-right"});
        }, 60 * 1000);
        return () => {
            clearInterval(interval);
        };
    }, [po]);

    const getBoardData = async (id) => {
        // Swal.showLoading({ });
        // Swal.fire({
        //     position: "bottom",
        //     text: 'loading...',
        //     allowEscapeKey: false,
        //     allowOutsideClick: false,
        //     showConfirmButton: false,
        //     didOpen: () => {
        //         Swal.showLoading();
        //     }
        // })
        setPo(id);


        // 如果已有正在进行的请求，先中断它
        if (abortControllerRef1.current) abortControllerRef1.current.abort();
        if (abortControllerRef2.current) abortControllerRef2.current.abort();
        if (abortControllerRef3.current) abortControllerRef3.current.abort();


        // 创建新的 AbortController
        const controller1 = new AbortController();
        const controller2 = new AbortController();
        const controller3 = new AbortController();

        // 更新 ref
        abortControllerRef1.current = controller1;
        abortControllerRef2.current = controller2;
        abortControllerRef3.current = controller3;


        // 使用 Promise.all 处理多个请求
        Promise.all([
            axios.get(`${window.api_url}/user`, { headers, signal: controller1.signal }),
            axios.get(`${window.api_url}/pos/station?pid=${id}`, { headers, signal: controller2.signal }),  // 第一个请求
            axios.get(`${window.api_url}/pos/device-test-result?pid=${id}`, { headers, signal: controller3.signal })  // 第二个请求
        ])
            .then(async ([userResponse, stationResponse, deviceTestResultResponse]) => {

                var station_name_list = {
                    1: "board",
                    2: "qc"
                };
                let user;
                if (userResponse.data.code === "0000") {
                    user = userResponse.data.data;
                }
                // console.log(user.station_type);
                let newStation = {};
                if (stationResponse.data.code === "0000") {
                    const StationData = stationResponse.data.data;

                    StationData.forEach(item => {
                        if (item.station_type != null) {
                            var stationTypeName;
                            switch (item.station_type.id) {
                                case 1:
                                case 2:
                                    stationTypeName = station_name_list[item.station_type.id]
                                    break;
                                default:
                                    stationTypeName = item.station_type.id
                            }
                            if (!newStation[stationTypeName]) {
                                newStation[stationTypeName] = [];
                            }
                            if (!newStation[stationTypeName].includes(item.name)) {
                                newStation[stationTypeName].push(item.name);
                            }
                        }
                    });
                    if (newStation["board"] == null) {
                        newStation["board"] = [];
                    }
                    if (newStation["qc"] == null) {
                        newStation["qc"] = [];
                    }

                    setStation(StationData.length === 0 ? { board: [], qc: [], } : newStation);


                    if (deviceTestResultResponse.data.code === "0000") {
                        const data = deviceTestResultResponse.data.data

                        const typeDatesMap = {};
                        let totalResults = {};
                        let successCount = {};
                        let boardResultsList = [];
                        let qcResultsList = [];
                        let assemblyResultsList = [];
                        let packedResultsList = [];
                        let packedResultsP = {
                            total: 0,
                            device_packed: 0,
                            box_ready: 0,
                            carton_ready: 0,
                            shipped: 0,
                        }
                        let runDays = {
                            board: 0,
                            qc: 0,
                            assembly: 0,
                        };
                        Object.entries(runDays).forEach(([key, value], index) => {
                            typeDatesMap[key] = new Set();
                        });

                        await data.forEach(item => {
                            ////console.log(item)
                            const deviceTestResults = item.device_test_results;
                            deviceTestResults.forEach(result => {

                                const stationTypeName = station_name_list[result.station_type_id] // 獲取 station 中的 type
                                const date = result.timestamp.split('T')[0]; // 提取日期部分
                                const name = result.station_name;
                                // console.log(name)

                                // 將日期加入對應的 type 的 Set 中
                                typeDatesMap[stationTypeName].add(date);
                                // 初始化 totalResults 和 successCount 的嵌套结构
                                if (!totalResults[stationTypeName]) {
                                    totalResults[stationTypeName] = { "All": 0 };
                                }
                                if (!totalResults[stationTypeName][name]) {
                                    totalResults[stationTypeName][name] = 0;
                                }
                                if (!successCount[stationTypeName]) {
                                    successCount[stationTypeName] = { "All": 0 };
                                }
                                if (!successCount[stationTypeName][name]) {
                                    successCount[stationTypeName][name] = 0;
                                }

                                totalResults[stationTypeName]["All"] += 1;
                                totalResults[stationTypeName][name] += 1;

                                if (result.result === 1) {
                                    successCount[stationTypeName]["All"] += 1;
                                    successCount[stationTypeName][name] += 1;
                                }
                                if (result != null) {
                                    var items_result = JSON.parse(result.items_result);
                                    var item_status_string = { 0: "", 1: "PASS", "2": "FAIL" };
                                    switch (result.station_type_id) {
                                        case "1":
                                        case 1:
                                            boardResultsList.push({
                                                "serial_number": result.serial_number,
                                                "tap": result.tap,
                                                "subcontractor": item.subcontractor_name,
                                                "factory": item.factory_name,
                                                "station": result.station_name,
                                                "ps": item_status_string[items_result.ps],
                                                "mcu_flash": item_status_string[items_result.mcu_flash],
                                                "esp_flash": item_status_string[items_result.esp_flash],
                                                "radar": item_status_string[items_result.radar],
                                                "config": item_status_string[items_result.config],
                                                "wifi": item_status_string[items_result.wifi],
                                            })

                                            break;
                                        case "2":
                                        case 2:
                                            qcResultsList.push({
                                                "serial_number": item.serial_number,
                                                "tap": result.tap,
                                                "subcontractor": item.subcontractor_name,
                                                "factory": item.factory_name,
                                                "station": result.station_name,
                                                "fw": item_status_string[items_result.fw],
                                                "sn": item_status_string[items_result.sn],
                                                "usb": item_status_string[items_result.usb],
                                                "lens_check": item_status_string[items_result.lens_check],
                                                "wifi": item_status_string[items_result.wifi],
                                            })
                                            break;
                                    }

                                }
                            });

                            if (item.status > 1) {
                                if (!totalResults['assembly']) {
                                    totalResults['assembly'] = { "All": 0 };
                                }
                                if (!successCount['assembly']) {
                                    successCount['assembly'] = { "All": 0 };
                                }
                                if (item.assembled) {
                                    typeDatesMap['assembly'].add(item.assembled.split('T')[0]);
                                }
                                totalResults['assembly'].All++;
                                successCount['assembly'].All++;

                                assemblyResultsList.push({
                                    "serial_number": item.serial_number,
                                    "subcontractor": item.subcontractor_name,
                                    "factory": item.factory_name,
                                    "time": item.device_packed,
                                })
                            }

                            if (item.status > 3) {
                                packedResultsList.push({
                                    "serial_number": item.serial_number,
                                    "subcontractor": item.subcontractor_name,
                                    "factory": item.factory_name,
                                    "device_packed": item.device_packed,
                                    "box": item.box_ready,
                                    "carton": item.carton_ready,
                                    "shipped": item.shipped,
                                })

                                packedResultsP.total++;
                            }

                            switch (item.status) {
                                case 7:
                                    packedResultsP.shipped++;
                                case 6:
                                    packedResultsP.cartons_ready++;
                                case 5:
                                    packedResultsP.box_ready++;
                                case 4:
                                    packedResultsP.device_packed++;
                            }
                        });

                        // console.log(user.role.id === 4);
                        await setBQcData(qcResultsList);
                        await setBoardData(boardResultsList);
                        await setAssemblyData(assemblyResultsList);
                        await setPackedData(packedResultsList);
                        // console.log("assemblyResultsList:" + assemblyResultsList.length)
                        // console.log("packedResultsList:" + packedResultsList.length)
                        // console.log("boardResultsList:" + boardResultsList.length)
                        // console.log("qcResultsList:" + qcResultsList.length)

                        runDays = {
                            board: boardResultsList.length === 0 ? 1 : typeDatesMap['board'].size,
                            qc: qcResultsList.length === 0 ? 1 : typeDatesMap['qc'].size,
                            assembly: assemblyResultsList.length === 0 ? 1 : typeDatesMap['assembly'].size
                        };

                        // 這裡等同於你的 while 循環，直到 runDays['assembly'] != 0

                        // 生成所需的 JSON 结构
                        let resultJSON = {};

                        await Object.keys(newStation).forEach(newStationIndex => {
                            if (!resultJSON[newStationIndex]) {
                                resultJSON[newStationIndex] = {};
                            }
                            Object.keys(newStation[newStationIndex]).forEach(stationNo => {
                                resultJSON[newStationIndex][newStation[newStationIndex][stationNo]] = {
                                    wip: "-",
                                    passed: "-",
                                    failed: "-",
                                    performance: '-', // 这个值如何计算请自行定义
                                    yield: "-"
                                };
                            });
                        });
                        await Object.keys(totalResults).forEach(stationTypeName => {

                            if (user.role.id === 4 && user.station_type !== stationTypeName) {
                                return;
                            }

                            if (resultJSON[stationTypeName] == null) {
                                resultJSON[stationTypeName] = {};
                            }
                            if (resultJSON[stationTypeName]['All'] == null) {
                                resultJSON[stationTypeName]['All'] = {};
                            }
                            resultJSON[stationTypeName]['All'] = {
                                total: totalResults[stationTypeName]['All'],
                                passed: successCount[stationTypeName]['All'],
                                failed: totalResults[stationTypeName['All']] - successCount[stationTypeName]['All'],
                                performance: "-",
                                yield: `${((successCount[stationTypeName]['All'] / totalResults[stationTypeName]['All']) * 100).toFixed(2)}%`,
                            };
                            Object.keys(totalResults[stationTypeName]).forEach(tap => {


                                let statuonNum = 1;
                                if (tap == 'All' && newStation[stationTypeName] != null && newStation[stationTypeName].length > 1) {
                                    statuonNum = newStation[stationTypeName].length - 1;
                                }
                                // console.log(stationTypeName + `[${tap}]` + ":" + (totalResults[stationTypeName][tap] + '/' + runDays[stationTypeName] + '/' + ef[stationTypeName] + "/" + statuonNum));
                                resultJSON[stationTypeName][tap] = {
                                    wip: totalResults[stationTypeName][tap],
                                    passed: successCount[stationTypeName][tap],
                                    failed: totalResults[stationTypeName][tap] - successCount[stationTypeName][tap],
                                    performance: `${(totalResults[stationTypeName][tap] / runDays[stationTypeName] / ef[stationTypeName] / statuonNum * 100).toFixed(2)}%`,
                                    yield: `${((successCount[stationTypeName][tap] / totalResults[stationTypeName][tap]) * 100).toFixed(2)}%`,
                                };
                            });
                        });
                        // if (data.length === 0) 
                        {
                            if (resultJSON["board"] == null) {
                                resultJSON["board"] = {};
                            }

                            if (resultJSON["board"]["All"] == null) {
                                resultJSON["board"]['All'] = {
                                    wip: "-",
                                    passed: "-",
                                    failed: "-",
                                    performance: "-",
                                    yield: "-",
                                };
                            }
                            if (resultJSON["qc"] == null) {
                                resultJSON["qc"] = {};
                            }

                            if (resultJSON["qc"]["All"] == null) {
                                resultJSON["qc"]['All'] = {
                                    wip: "-",
                                    passed: "-",
                                    failed: "-",
                                    performance: "-",
                                    yield: "-",
                                };
                            }
                            if (resultJSON["assembly"] == null) {
                                resultJSON["assembly"] = {};
                            }

                            if (resultJSON["assembly"]["All"] == null) {
                                resultJSON["assembly"]['All'] = {
                                    wip: "-",
                                    passed: "-",
                                    failed: "-",
                                    performance: "-",
                                    yield: "-",
                                };
                            }
                            if (resultJSON["packed"] == null) {
                                resultJSON["packed"] = {};
                            }
                            if (resultJSON["packed"]["All"] == null) {
                                // console.log(user.role < 4 )
                                if (user.role.id < 4 || user.station_type == "packed") {
                                    resultJSON["packed"]['All'] = {
                                        total: packedResultsP.total,
                                        device_packed: packedResultsP.device_packed,
                                        box_ready: packedResultsP.box_ready,
                                        carton_ready: packedResultsP.carton_ready,
                                        shipped: packedResultsP.shipped
                                    };
                                } else {
                                    resultJSON["packed"]['All'] = {
                                        total: "-",
                                        device_packed: "-",
                                        box_ready: "-",
                                        carton_ready: "-",
                                        shipped: "-",
                                    }
                                }
                            }

                        }

                        setData(resultJSON);
                        Swal.close()
                    }
                }
                setLoading(false); // 两个请求都完成后，更新 loading 状态为 false
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // 如果是 401 错误，重定向到登录页面
                    window.location.href = '/login';  // 直接使用相对路径 '/login'
                } else if (axios.isCancel(error)) {
                    console.log('Request was cancelled');
                } else {
                    console.error("獲取數據時出錯了！", error);
                }
                setLoading(false); // 请求出错，更新 loading 状态为 false
            });


        return true;
    }

    const handleRowSelection = (selectedRows) => {
        if (selectedRows.length > 0) {
            setPo(selectedRows[0].id);
            setSelectedPoData(selectedRows[0]);
            // console.log("handleRowSelection")
            getBoardData(selectedRows[0].id);
        }
    };


    const handleFactoryChange = async (id) => {
        setFactory(id)
        // 定義 headers
        const params = {
            fid: id
        };
        await axios.get(`${window.api_url}/ef`, { headers, params })
            .then(async response => {
                const rows = response.data.data;
                const efList = {};
                await rows.map(row => {
                    switch (row.station_type.id) {
                        case 1:
                            efList["board"] = row.ef;
                            break;
                        case 2:
                            efList["qc"] = row.ef;
                        case 3:
                            efList["assembly"] = row.ef;
                    }

                })

                await Object.keys(ef).forEach((value, index) => {
                    ////console.log(value, index)
                    if (efList[value] == null) {
                        efList[value] = 500;
                    }
                });
                await setEf(efList);
            })
            .catch(error => {

            });

        // 發送帶有 headers 的請求
        await axios.get(`${window.api_url}/pos`, { headers, params })
            .then(loadPoResponse)
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // 如果是 401 错误，重定向到登录页面
                    window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                } else {
                    console.error("獲取數據時出錯了！", error);
                }
            });

    };

    if (loading) {
        return Swal.showLoading();
    }
    Swal.close()


    return (
        <>
            <ToastContainer />
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <title>Dashborad</title>
                </Helmet>
            </HelmetProvider>
            <Header />

            <Row >

                <Col xl={10} lg={12}>
                    <Row>
                        <Col md={10}>
                            <Row>
                                <ToggleButtonGroup type="radio" name="factory" defaultValue={factory} onChange={handleFactoryChange}>
                                    <ToggleButton className="factory-switch-btn"
                                        variant="outline-primary" id="tbg-radio-0" value='all'
                                        key="0">All</ToggleButton>
                                    {factoriesBtn.map((button, index) => (
                                        <ToggleButton className="factory-switch-btn"
                                            variant="outline-primary" id={`tbg-radio-${index + 1}`} value={button.id}
                                            key={button.id}  >{button.name}</ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Row>
                        </Col>
                        {/* <Col md={2} className="mt-1">
                            <FormSelect>
                                <option>PO#</option>
                            </FormSelect>
                        </Col> */}
                    </Row><br />
                    <Row>
                        <Row>
                            <h4>POs</h4>
                        </Row>
                        <Row className="home-table-box me-0 pe-0 mb-2">
                            <Col className="pe-0">
                                <PoTable data={poData} onRowSelection={handleRowSelection} />
                            </Col>
                        </Row>
                    </Row>

                    <Row className="m-b-main-box">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                            <Col xl={3} md={6} style={{ padding: 0 }}>
                                <div className="home-bottom-tap p-3 m-1">
                                    <Row className="mt-2">
                                        <h5>Board Tester Station</h5>
                                    </Row>
                                    <Row className="ms-1">
                                        <Col md={9} xs={10} className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                            <Row className="pt-1">
                                                <Col md={8} xs={7} className="text-start pe-1">Total :</Col>
                                                <Col md={4} xs={5} className="ps-0">{data.board["All"].wip}</Col>
                                            </Row>
                                        </Col>
                                        <Col md={3} xs={2}>
                                            <CSVLink data={boardData} headers={boardDataHeaders} filename={"board.csv"} key={boardData.length}>
                                                <Button type="button" size="sm"><FontAwesomeIcon icon={faFileCsv} /></Button>
                                            </CSVLink>
                                        </Col>
                                    </Row>
                                    <Row className=" mt-1 ms-1">
                                        <Col md={9} xs={10} className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                            <Row className="pt-1">
                                                <Col md={8} xs={7} className="text-start text-nowrap  pe-1">Total Yield :</Col>
                                                <Col md={4} xs={5} className="ps-0">{data.board["All"].yield}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col>
                                            <Nav variant="pills" style={{ display: "block" }}>
                                                {station.board.map((value, index) => (
                                                    <Nav.Item key={`item-board-${index + 1}`}>
                                                        <Nav.Link eventKey={index + 1} className="mt-1" >{value}</Nav.Link>
                                                    </Nav.Item>
                                                ))}
                                                <Nav.Item key={`item-0`}>
                                                    <Nav.Link eventKey="0" className="mt-1" >All</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>
                                    <hr />

                                    <Tab.Content>
                                        {station.board.map((value, index) => (
                                            <Tab.Pane eventKey={index + 1} key={index}>
                                                <Row>
                                                    <Col className="px-3">
                                                        <Row className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                            <Col md="8" xs={6} className="text-start">WIP #{value}:</Col>
                                                            <Col>{data.board[value].wip}</Col>
                                                        </Row>
                                                        <Row className="mt-2 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                            <Col md="8" xs={6} className="text-start">Passed #{value}:</Col>
                                                            <Col>{data.board[value].passed}</Col>
                                                        </Row>
                                                        <Row className="mt-2 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                            <Col md="8" xs={6} className="text-start">Failed #{value}:</Col>
                                                            <Col>{data.board[value].failed}</Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col className="px-3">
                                                        <Row className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                            <Col md="8" xs={6} className="text-start">Performance #{value}:</Col>
                                                            <Col>{data.board[value].performance}</Col>
                                                        </Row>
                                                        <Row className="mt-2 mb-3 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                            <Col md="8" xs={6} className="text-start">Yield #{value}:</Col>
                                                            <Col>{data.board[value].yield}</Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                        ))}
                                        <Tab.Pane eventKey="0">
                                            <Row>
                                                <Col className="px-3">
                                                    <Row className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">WIP All:</Col>
                                                        <Col>{data.board["All"].wip}</Col>
                                                    </Row>
                                                    <Row className="mt-2 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">Passed All:</Col>
                                                        <Col>{data.board["All"].passed}</Col>
                                                    </Row>
                                                    <Row className="mt-2 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">Failed All:</Col>
                                                        <Col>{data.board["All"].failed}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col className="px-3">
                                                    <Row className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">Performance All:</Col>
                                                        <Col>{data.board["All"].performance}</Col>
                                                    </Row>
                                                    <Row className="mt-2 mb-3 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">Yield All:</Col>
                                                        <Col>{data.board["All"].yield}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                    </Tab.Content>

                                </div>

                                <Col md={12} style={{ padding: 0 }} >
                                    <div className="home-bottom-tap p-3 m-1">
                                        <Form.Group as={Row} >
                                            <Form.Label column md={2} className="text-end" style={{ "paddingRight": 0, "lineHeight": "16px" }} >
                                                EF:&nbsp;
                                            </Form.Label>
                                            <Col md={6}>
                                                <Form.Control size="sm" type="number" onChange={handleChange} value={ef.board} name="board" id="board" />
                                            </Col>
                                            <Col md={2}>
                                                <Button size="sm" type="button" onClick={() => handleOnClick("board")}>Apply</Button>
                                            </Col>
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Col>
                        </Tab.Container>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                            <Col xl={3} md={6} style={{ padding: 0 }}>
                                <div className="home-bottom-tap p-3 m-1">
                                    <Row className="mt-2">
                                        <h5>Assembly Station</h5>
                                    </Row>
                                    <Row className="ms-1">
                                        <Col md={9} xs={10} className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                            <Row className="pt-1">
                                                <Col md={8} xs={7} className="text-start pe-1">Total :</Col>
                                                <Col md={4} xs={5} className="ps-0">{data.assembly["All"].wip}</Col>
                                            </Row>

                                        </Col>
                                        <Col md={3} xs={2}>

                                            <CSVLink data={assemblyData} headers={assemblyDataHeaders} filename={"assembly.csv"} key={assemblyData.length}>
                                                <Button type="button" size="sm"><FontAwesomeIcon icon={faFileCsv} /></Button>
                                            </CSVLink>
                                        </Col>

                                    </Row>
                                    <Row className=" mt-1 ms-1">
                                        <Col md={9} xs={10} className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                            <Row className="pt-1">
                                                <Col md={8} xs={7} className="text-start text-nowrap  pe-1">Total Yield :</Col>
                                                <Col md={4} xs={5} className="ps-0">{data.assembly["All"].yield}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col>
                                            <Nav variant="pills" style={{ display: "block" }}>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="1" className="mt-1" >All</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>
                                    <hr />

                                    <Tab.Content>
                                        <Tab.Pane eventKey="1">
                                            <Row>
                                                <Col className="px-3">
                                                    <Row className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">WIP All:</Col>
                                                        <Col>{data.assembly["All"].wip}</Col>
                                                    </Row>
                                                    <Row className="mt-2 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">Passed All:</Col>
                                                        <Col>{data.assembly["All"].passed}</Col>
                                                    </Row>
                                                    <Row className="mt-2 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">Failed All:</Col>
                                                        <Col>{data.assembly["All"].failed}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col className="px-3">
                                                    <Row className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">Performance All:</Col>
                                                        <Col>{data.assembly["All"].performance}</Col>
                                                    </Row>
                                                    <Row className="mt-2 mb-3 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">Yield All:</Col>
                                                        <Col>{data.assembly["All"].yield}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>

                                <Col md={12} style={{ padding: 0 }} >
                                    <div className="home-bottom-tap p-3 m-1">
                                        <Form.Group as={Row} >
                                            <Form.Label column md={2} className="text-end" style={{ "paddingRight": 0, "lineHeight": "16px" }} >
                                                EF:&nbsp;
                                            </Form.Label>
                                            <Col md={6}>
                                                <Form.Control size="sm" type="number" onChange={handleChange} value={ef.assembly} name="assembly" id="assembly" />
                                            </Col>
                                            <Col md={2}>
                                                <Button size="sm" type="button" onClick={() => handleOnClick("assembly")}>Apply</Button>
                                            </Col>
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Col>
                        </Tab.Container>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                            <Col xl={3} md={6} style={{ padding: 0 }}>
                                <div className="home-bottom-tap p-3 m-1">
                                    <Row className="mt-2">
                                        <h5>QC JIG Station</h5>
                                    </Row>
                                    <Row className="ms-1">
                                        <Col md={9} xs={10} className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                            <Row className="pt-1">
                                                <Col md={8} xs={7} className="text-start pe-1">Total :</Col>
                                                <Col md={4} xs={5} className="ps-0">{data.qc["All"].wip}</Col>
                                            </Row>
                                        </Col>
                                        <Col md={3} xs={2}>
                                            <CSVLink data={qcData} headers={qcDataHeaders} filename={"qc.csv"} key={qcData.length}>
                                                <Button type="button" size="sm"><FontAwesomeIcon icon={faFileCsv} /></Button>
                                            </CSVLink>
                                        </Col>
                                    </Row>
                                    <Row className=" mt-1 ms-1">
                                        <Col md={9} xs={10} className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                            <Row className="pt-1">
                                                <Col md={8} xs={7} className="text-start text-nowrap  pe-1">Total Yield :</Col>
                                                <Col md={4} xs={5} className="ps-0">{data.qc["All"].yield}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col>
                                            <Nav variant="pills" style={{ display: "block" }}>

                                                {station.qc.map((value, index) => (
                                                    <Nav.Item key={`item-qc-${index + 1}`}>
                                                        <Nav.Link eventKey={index + 1} className="mt-1">{value}</Nav.Link>
                                                    </Nav.Item>
                                                ))}
                                                <Nav.Item>
                                                    <Nav.Link eventKey="0" key={`item-qc-0`} className="mt-1">All</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>
                                    <hr />

                                    <Tab.Content>
                                        {station.qc.map((value, index) => (
                                            <Tab.Pane eventKey={index + 1} key={`staion-qc-${index + 1}`} >
                                                <Row>
                                                    <Col className="px-3">
                                                        <Row className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                            <Col md="8" xs={6} className="text-start">WIP #{value} :</Col>
                                                            <Col>{data.qc[value].wip}</Col>
                                                        </Row>
                                                        <Row className="mt-2 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                            <Col md="8" xs={6} className="text-start">Passed #{value} :</Col>
                                                            <Col>{data.qc[value].passed}</Col>
                                                        </Row>
                                                        <Row className="mt-2 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                            <Col md="8" xs={6} className="text-start">Failed #{value} :</Col>
                                                            <Col>{data.qc[value].failed}</Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col className="px-3">
                                                        <Row className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                            <Col md="8" xs={6} className="text-start">Performance #{value}:</Col>
                                                            <Col>{data.qc[value].performance}</Col>
                                                        </Row>
                                                        <Row className="mt-2 mb-3 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                            <Col md="8" xs={6} className="text-start">Yield #{value}:</Col>
                                                            <Col>{data.qc[value].yield}</Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                        ))}
                                        <Tab.Pane eventKey="0"><Row>
                                            <Col className="px-3">
                                                <Row className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                    <Col md="8" xs={6} className="text-start">WIP All:</Col>
                                                    <Col>{data.qc["All"].wip}</Col>
                                                </Row>
                                                <Row className="mt-2 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                    <Col md="8" xs={6} className="text-start">Passed All:</Col>
                                                    <Col>{data.qc["All"].passed}</Col>
                                                </Row>
                                                <Row className="mt-2 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                    <Col md="8" xs={6} className="text-start">Failed All:</Col>
                                                    <Col>{data.qc["All"].failed}</Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                            <hr />
                                            <Row>
                                                <Col className="px-3">
                                                    <Row className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">Performance All:</Col>
                                                        <Col>{data.qc["All"].performance}</Col>
                                                    </Row>
                                                    <Row className="mt-2 mb-3 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">Yield All:</Col>
                                                        <Col>{data.qc["All"].yield}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                                <Col md={12} style={{ padding: 0 }} >
                                    <div className="home-bottom-tap p-3 m-1">
                                        <Form.Group as={Row} >
                                            <Form.Label column md={2} className="text-end" style={{ "paddingRight": 0, "lineHeight": "16px" }} >
                                                EF:&nbsp;
                                            </Form.Label>
                                            <Col md={6}>
                                                <Form.Control size="sm" type="number" onChange={handleChange} value={ef.qc} name="qc" id="qc" />
                                            </Col>
                                            <Col md={2}>
                                                <Button size="sm" type="button" onClick={() => handleOnClick("qc")}>Apply</Button>
                                            </Col>
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Col>
                        </Tab.Container>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                            <Col xl={3} md={6} style={{ padding: 0 }}>
                                <div className="home-bottom-tap p-3 m-1">
                                    <Row className="mt-2">
                                        <h5>Packed</h5>
                                    </Row>
                                    <Row className="ms-1">
                                        <Col md={9} xs={10} className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                            <Row className="pt-1">
                                                <Col md={8} xs={7} className="text-start pe-1">Total :</Col>
                                                <Col md={4} xs={5} className="ps-0">{data.packed["All"].total}</Col>
                                            </Row>

                                        </Col>

                                        <Col md={3} xs={2}>
                                            <CSVLink data={packedData} headers={packedDataHeaders} filename={"Packed.csv"} key={packedData.length}>
                                                <Button type="button" size="sm"><FontAwesomeIcon icon={faFileCsv} /></Button>
                                            </CSVLink>
                                        </Col>

                                    </Row>
                                    <Row className=" mt-1 ms-1">
                                        <Col md={9} xs={10} className="border-1px" style={{ margin: " 0 -0.2rem", borderColor: "#FFFFFF" }}>
                                            <Row className="pt-1">
                                                &nbsp;
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col>
                                            <Nav variant="pills" style={{ display: "block" }}>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="1" className="mt-1">All</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>
                                    <hr />

                                    <Tab.Content>
                                        <Tab.Pane eventKey="1">
                                            <Row>
                                                <Col className="px-3">
                                                    <Row className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">Devices Packed :</Col>
                                                        <Col>{data.packed["All"].device_packed}</Col>
                                                    </Row>
                                                    <Row className="mt-2 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">Boxes of 50:</Col>
                                                        <Col>{data.packed["All"].box_ready}</Col>
                                                    </Row>
                                                    <Row className="mt-2 border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">Carton of 300:</Col>
                                                        <Col>{data.packed["All"].carton_ready}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col className="px-3">
                                                    <Row className="border-1px" style={{ margin: " 0 -0.2rem" }}>
                                                        <Col md="8" xs={6} className="text-start">Progress :</Col>
                                                        <Col>-</Col>
                                                    </Row>
                                                    <Row className="mt-2 mb-3 border-1px" style={{ borderColor: "#FFFFFF" }}>
                                                        &nbsp;
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </Col>
                        </Tab.Container>
                    </Row>
                    <Row>
                        <Col md={12} style={{ padding: 0 }}>
                            <div className="home-bottom-tap p-3 m-1">
                                EF – efficiency factor. Max theoretic capacity of the station per day. Predefined constant.<br />Note: Performance when “All” stations button pressed is:100% * (Passed #x + Failed #x) / RunDays / EF / numberOfStations
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={2} md={12} style={{ backgroundColor: '#eeeeee' }}>Message</Col>
            </Row><br />

        </>
    );

}

export default Home