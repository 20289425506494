import React, { useState, useEffect } from 'react';
import '../../config/config.js';
import Header from '../../components/Header.js'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Col, Row } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import Forbidden from '../../components/Forbidden.js';


const StationsForm = () => {
    const { id } = useParams();

    // const [options, setOptions] = useState([]);
    // const [typeOptions, setTypeOptions] = useState([]);
    const [loading, setLoading] = useState(true); // 初始状态为 true，表示正在加载数据
    const [errors, setErrors] = useState('');

    const [formData, setFormData] = useState({
        line: '',
        name: '',
        component: {
            name: "",
            hardware_type: ""
        },
        stationType: '',
        fw_version: '',
        id: id
    });
    // const handleSelectChange = (selectedOption, actionMeta) => {
    //     // 检查 actionMeta 是否存在
    //     if (actionMeta) {
    //         const { name } = actionMeta || {};
    //         if (name) {
    //             // 更新 formData 中的值
    //             setFormData({
    //                 ...formData,
    //                 [name]: selectedOption ? selectedOption.value : ''  // 单选框处理
    //             });
    //         }
    //     } else {
    //         console.error('没有提供 actionMeta 或 selectedOption。');
    //     }
    // };
    const handleChange = (e, actionMeta) => {
        const { name, value, options, type } = e.target || {};
        // 处理多选下拉菜单
        if (name.includes(".")) {
            const [parent, child] = name.split(".");
            setFormData({
                ...formData,
                [parent]: {
                    ...formData[parent],
                    [child]: value
                }
            });
        }
        else if (actionMeta && actionMeta.action === 'select-multi') {
            setFormData({
                ...formData,
                [actionMeta.name]: actionMeta.selectedOptions ? actionMeta.selectedOptions.map(option => option.value) : [],
            });

            // 处理单选按钮
        } else if (type === 'radio') {
            setFormData({ ...formData, [name]: value });


        } else if (type === 'select-one') {
            setFormData({
                ...formData,
                [name]: value
            });
            // 处理复选框
        } else if (type === 'text' || type === 'email' || type === 'number' || type === 'password') {
            setFormData({ ...formData, [name]: value });

            // 处理选择框
        } else if (type === 'select-multiple') {
            setFormData({
                ...formData,
                [name]: Array.from(options).filter(option => option.selected).map(option => option.value),
            });
        }
    };

    // const handleMultiSelectChange = (selectedOptions) => {
    //     setFormData({
    //         ...formData,
    //         ['components']: selectedOptions ? selectedOptions.map(option => option.value) : [],
    //     });

    //    //console.log(selectedOptions);
    // };

    useEffect(() => {
        if (errors) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: errors,
            });
        }
    }, [errors]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const headers = {
            'Authorization': `Bearer ${token}`, // 替換為你的 token
            'Content-Type': 'application/json',
        };

        // 使用 Promise.all 处理多个请求
        // Promise.all([
        //     axios.get(`${window.api_url}/lines`, { headers }),  // 第一个请求
        //     axios.get(`${window.api_url}/stations/types`, { headers }),  // 第一个请求
        //     // axios.get(`${window.api_url}/station-components`, { headers }),
        // ])
        //     .then(([lineResponse, stationTypeResponse, stationComponentsResponse]) => {
        //         if (lineResponse.data.code === "0000") {
        //             const formattedOptions = lineResponse.data.data.map(line => ({
        //                 value: line.id,
        //                 label: `[${line.subcontractor.name}]-[${line.factory.name}] ${line.name}`,
        //             }));
        //             setOptions(formattedOptions);
        //         }
        //         if (stationTypeResponse.data.code === "0000") {
        //             const formattedOptions = stationTypeResponse.data.data.content.map(s => ({
        //                 value: s.id,
        //                 label: `${s.name}`,
        //             }));
        //             setTypeOptions(formattedOptions);
        //         }
        //         if (stationComponentsResponse.data.code === "0000") {
        //             const formattedOptions = stationComponentsResponse.data.data.map(s => ({
        //                 value: s.id,
        //                 label: `${s.name}`,
        //             }));
        //             setComponentsOptions(formattedOptions);
        //         }

        //         setLoading(false); // 两个请求都完成后，更新 loading 状态为 false
        //     })
        //     .catch(error => {
        //         if (error.response && error.response.status === 401) {
        //             // 如果是 401 错误，重定向到登录页面
        //             window.location.href = '/login';  // 直接使用相对路径 '/login'
        //         } else {
        //             console.error("獲取數據時出錯了！", error);
        //         }
        //         console.error("获取数据时出错了！", error);
        //         setLoading(false); // 请求出错，更新 loading 状态为 false
        //     });
        if (id) {
            axios.get(`${window.api_url}/stations/${id}`, { headers, })
                .then((response) => {
                   //console.log(response.data.code);
                    if (response.data.code === "0000") {
                        // if (response.data.data.line != null) {
                        //     response.data.data.line = response.data.data.line.id;
                        // }
                        // if (response.data.data.station_type != null) {
                        //     response.data.data.stationType = response.data.data.station_type.id;
                        // }

                        // response.data.data.components = [];
                        // if (response.data.data.station_component_link != null) {
                        //     response.data.data.station_component_link.map((item) => {
                        //         response.data.data.components.push(item.station_component.id)
                        //         return item;
                        //     })
                        // }

                        if (response.data.data.component == null) {
                            response.data.data.component = {
                                name: "",
                                hardware_type: "",
                                hardware_version: "",
                                firmware_version: ""
                            }
                        }
                        setFormData(response.data.data);
                    }

                    setLoading(false); // 两个请求都完成后，更新 loading 状态为 false
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        // 如果是 401 错误，重定向到登录页面
                        window.location.href = '/login';  // 直接使用相对路径 '/login'
                    } else {
                        console.error("獲取數據時出錯了！", error);
                    }
                    console.error("获取数据时出错了！", error);
                    setLoading(false); // 请求出错，更新 loading 状态为 false
                });
        } else {

        }
    }, [id]); // 空依赖项数组表示这个 useEffect 只会运行一次


    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.name === "") {
            setErrors('Name is Required');
        }
        else {
            setErrors('');


            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Bearer ${token}`, // 替換為你的 token
                'Content-Type': 'application/x-www-form-urlencoded',
            };

            if (id) {

                axios.put(`${window.api_url}/stations/${id}`, formData, { headers },)
                    .then(response => {
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            window.location.href = "/stations";
                        });
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            // 如果是 401 错误，重定向到登录页面
                            window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                        } else {
                            console.error("獲取數據時出錯了！", error);
                        }
                    });
            } else {

                axios.post(`${window.api_url}/stations`, formData, { headers })
                    .then(response => {
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            window.location.href = "/stations";
                        });
                    })
                    .catch(error => {
                        // 處理錯誤
                        if (error.response && error.response.status === 401) {
                            // 如果是 401 错误，重定向到登录页面
                            window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                        } else {
                            console.error("獲取數據時出錯了！", error);
                        }
                    });
            }
        }
    };

    if (loading) {
        return Swal.showLoading(); // 显示加载状态
    }
    Swal.close()
    if (window.userRole !== "1") {
        return (<><Forbidden></Forbidden></>);
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <title>Stations Form</title>
                </Helmet>
            </HelmetProvider>
            <Header />

            <Container style={{ 'backgroundColor': 'white', "padding": "30px" }} className="mt-5">
                <Row>
                    <h4 className="mt-3">Stations Form</h4>
                </Row>

                <Form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Name
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" disabled value={formData.name || ''} placeholder="Name" onChange={handleChange} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            IP
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" disabled value={formData.ip || ''} placeholder="ip" onChange={handleChange} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            FW Version
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" disabled value={formData.fw_version || ''} placeholder="fw_version" onChange={handleChange} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Mac
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" disabled value={formData.mac || ''} placeholder="mac" onChange={handleChange} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            Line
                        </Form.Label>
                        <Col sm="10">
                            {/* <Select
                                name='line'
                                options={options}
                                value={options.find(option => option.value === formData.line)} // 设置选中的值
                                onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'line' })}
                            /> */}
                            <Form.Control type="text" disabled value={
                                formData.line
                                    ? `[${formData.line.subcontractor.name}]-[${formData.line.factory.name}] ${formData.line.name}`
                                    : ''
                            } />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            Type
                        </Form.Label>
                        <Col sm="10">
                            {/* <Select
                                name='stationType'
                                options={typeOptions}
                                value={typeOptions.find(option => option.value === formData.stationType)} // 设置选中的值
                                onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'stationType' })}
                            /> */}
                            <Form.Control type="text" disabled value={formData.station_type.name || ''} onChange={handleChange} />
                        </Col>
                    </Form.Group>

                    <div style={{position:'absolute',lineHeight:'2.4'}}>Component</div>
                    <div style={{ paddingLeft: "185px" }}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                Name <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Col sm="9">
                                <Form.Control type="text" name='component.name' value={formData.component.name || ''} placeholder="component name" onChange={handleChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                Hardware Type
                            </Form.Label>
                            <Col sm="9">
                                <Form.Control type="text" name='component.hardware_type' value={formData.component.hardware_type || ''} placeholder="component hardware type" onChange={handleChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                Hardware Version
                            </Form.Label>
                            <Col sm="9">
                                <Form.Control type="text" name='component.hardware_version' value={formData.component.hardware_version || ''} placeholder="component hardware version" onChange={handleChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                Firmware Version
                            </Form.Label>
                            <Col sm="9">
                                <Form.Control type="text" name='component.firmware_version' value={formData.component.firmware_version || ''} placeholder="component firmware version" onChange={handleChange} />
                            </Col>
                        </Form.Group>
                    </div>
                    {/* <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            Components
                        </Form.Label>
                        <Col sm="10">
                            <Select
                                isMulti
                                options={componentsOptions}
                                onChange={handleMultiSelectChange}
                                value={componentsOptions.filter(option => formData.components?.includes(option.value))}  // 使用 optional chaining
                            />
                        </Col>
                    </Form.Group> */}
                    {errors && (
                        <Row>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <span style={{ color: 'red', fontSize: '12px' }}>{errors}</span>
                            </Col>
                        </Row>
                    )}
                    <div style={{ "textAlign": "right" }}>
                        <Button type="submit">Submit</Button>
                    </div>

                </Form>
            </Container>
        </>
    );

}

export default StationsForm