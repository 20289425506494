import React, { useState, useEffect } from "react";
import '../config/config.js';
import Header from '../components/Header.js'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import DatalogTable from '../components/DatalogTable.js';
import { Row } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import axios from "axios";
import Swal from "sweetalert2";

const data = [
    {
        sn: '214232400105',
        site: 'FTCH 1',
        jig: '2',
        line: '1',
        qc_jig: '2',
        wp: <Badge bg="danger">Fail</Badge>,
        status: 'In Quarantine',
        reflow: 'Y',
        radar: <Badge bg="success">Pass</Badge>,
        mcu: <Badge bg="success">Pass</Badge>,
        ps: <Badge bg="success">Pass</Badge>,
        esp: <Badge bg="danger">Fail</Badge>,
        sn_t: <Badge bg="danger">Pass</Badge>,
        wifi_t: <Badge bg="danger">Pass</Badge>,
        cfg: '-',
        sn_qc: '-',
        wifi_qc: '-',
        box: '-',
        carton: '-',
        pallet: '-',
    }
];

const DataLogs = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // 初始状态为 true，表示正在加载数据
    

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <title>Data Log Table</title>
                </Helmet>
            </HelmetProvider>
            <Header />

            <Row>
                <h4>Data Log Table</h4>
            </Row>
            <DatalogTable data={data} />

        </>
    );

}

export default DataLogs