import React from "react";
import DataTable from "react-data-table-component";

class PoTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRows: [],
            manualSelection: false,  // 用來判斷是否是手動選擇
            dataLoaded: false        // 用來判斷是否是第一次給資料
        };
        this.selectRow = [];
    }

    componentDidMount() {
        this.selectFirstRow();
    }

    componentDidUpdate(prevProps) {

        if (!this.state.dataLoaded && prevProps.data !== this.props.data) {
            this.selectFirstRow();
            this.setState({dataLoaded: true });
        }
    }

    selectFirstRow = async () => {
        if (this.props.data.length > 0) {
            const firstRow = this.props.data[0];
            this.selectRow =  this.props.data[0];
            await this.setState({ selectedRows: [firstRow] });
            await this.props.onRowSelection([firstRow]);
           //console.log(firstRow)
        }
    };

    handleChange = ({ selectedRows }) => {
        // 比較新舊選中行，避免重複更新
        if (JSON.stringify(selectedRows) !== JSON.stringify(this.state.selectedRows)) {
            this.setState({ selectedRows, manualSelection: true });
            this.props.onRowSelection(selectedRows);
        }
    };

   
    columns = [
        // 列定义保持不变
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>PO#</strong>,
            selector: row => row.number,
            width: '110px',
            cell: row => <div style={{ whiteSpace: 'nowrap' }}>{row.number}</div>
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Site</strong>,
            selector: row => row.factory_name,
            cell: row => <div style={{ whiteSpace: 'nowrap' }}>{row.factory_name}</div>
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Start date</strong>,
            selector: row => row.started_at ? row.started_at : '-',
            width: '110px',
            cell: row => <div style={{ whiteSpace: 'nowrap' }}>{row.started_at}</div>
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Finished date</strong>,
            selector: row => row.finished_at ? row.finished_at : '-',
            width: '110px',
            cell: row => <div style={{ whiteSpace: 'nowrap' }}>{row.finished_at}</div>
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Total in PO</strong>,
            selector: row => row.amount,
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Passed</strong>,
            selector: row => row.passed,
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Failed</strong>,
            selector: row => row.failed,
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Ready For Shipment</strong>,
            selector: row => row.ready,
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Shipped</strong>,
            selector: row => row.shipped,
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>In Progress</strong>,
            selector: row => row.progress,
        },
        {
            name: <strong style={{ fontSize: '12px', textAlign: "center", width: "100%" }}>Yield</strong>,
            selector: row => row.yield,
        }
    ];

    paginationComponentOptions = {
        selectAllRowsItem: false,
        selectAllRowsItemText: 'ALL',
    };

    render() {
        const rowSelectCritera = (row) => {
            // 檢查是否選擇了該行
            return this.state.selectedRows.some(selectedRow => selectedRow.id === row.id);
        };
        return (
            <DataTable
                // 移除 key 属性
                pagination
                paginationPerPage={4}
                selectableRowSelected={rowSelectCritera}
                paginationComponentOptions={this.paginationComponentOptions}
                paginationRowsPerPageOptions={[4, 10, 20, 30]}
                highlightOnHover
                selectableRows
                selectableRowsSingle
                columns={this.columns}
                data={this.props.data}
                onSelectedRowsChange={this.handleChange}
                selectedRows={this.state.selectedRows} // 使用 selectedRows 属性
            />
        );

        
      
    }
}

export default PoTable;
