import React, { useState, useEffect } from 'react';
import '../../config/config.js';
import Header from '../../components/Header.js'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Col, Row } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2'
import Forbidden from '../../components/Forbidden.js';


const FactoriesForm = () => {
    const { id } = useParams();

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true); // 初始状态为 true，表示正在加载数据
    const [errors, setErrors] = useState('');

    const [formData, setFormData] = useState({
        name: '',
        address: '',
        country: '',
        phone:'',
        subcontractor: '',
        id: id
    });
    const handleSelectChange = (selectedOption, actionMeta) => {
        // 检查 actionMeta 是否存在
        if (actionMeta) {
            const { name } = actionMeta || {};
            if (name) {
                // 更新 formData 中的值
                setFormData({
                    ...formData,
                    [name]: selectedOption ? selectedOption.value : ''  // 单选框处理
                });
            }
        } else {
            console.error('没有提供 actionMeta 或 selectedOption。');
        }
    };
    const handleChange = (e, actionMeta) => {
        const { name, value, options, type } = e.target || {};
       //console.log(type);
        // 处理多选下拉菜单
        if (actionMeta && actionMeta.action === 'select-multi') {
            setFormData({
                ...formData,
                [actionMeta.name]: actionMeta.selectedOptions ? actionMeta.selectedOptions.map(option => option.value) : [],
            });

            // 处理单选按钮
        } else if (type === 'radio') {
            setFormData({ ...formData, [name]: value });


        } else if (type === 'select-one') {
            setFormData({
                ...formData,
                [name]: value
            });
            // 处理复选框
        }  else if (type === 'text' || type === 'email' || type === 'number' || type === 'password') {
            setFormData({ ...formData, [name]: value });

            // 处理选择框
        } else if (type === 'select-multiple') {
            setFormData({
                ...formData,
                [name]: Array.from(options).filter(option => option.selected).map(option => option.value),
            });
        }
    };


    useEffect(() => {
        if (errors) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: errors,
            });
        }
    }, [errors]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const headers = {
            'Authorization': `Bearer ${token}`, // 替換為你的 token
            'Content-Type': 'application/json',
        };

        // 使用 Promise.all 处理多个请求
        Promise.all([
            axios.get(`${window.api_url}/subcontractors`, { headers }),  // 第一个请求
        ])
            .then(([subcontractorResponse]) => {
                if (subcontractorResponse.data.code === "0000") {
                    const formattedOptions = subcontractorResponse.data.data.content.map(subcontractor => ({
                        value: subcontractor.id,
                        label: subcontractor.name,
                    }));
                    setOptions(formattedOptions);
                }

                setLoading(false); // 两个请求都完成后，更新 loading 状态为 false
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // 如果是 401 错误，重定向到登录页面
                    window.location.href = '/login';  // 直接使用相对路径 '/login'
                } else {
                    console.error("獲取數據時出錯了！", error);
                }
                console.error("獲取數據時出錯了！", error);
                setLoading(false); // 请求出错，更新 loading 状态为 false
            });
        if (id) {
            axios.get(`${window.api_url}/factories/${id}`, { headers, })
                .then((response) => {

                    if (response.data.code === "0000") {
                        response.data.data.subcontractor = response.data.data.subcontractor.id;
                       //console.log(response.data.data);
                        setFormData(response.data.data);
                    }

                    setLoading(false); // 两个请求都完成后，更新 loading 状态为 false
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        // 如果是 401 错误，重定向到登录页面
                        window.location.href = '/login';  // 直接使用相对路径 '/login'
                    } else {
                        console.error("獲取數據時出錯了！", error);
                    }
                    console.error("获取数据时出错了！", error);
                    setLoading(false); // 请求出错，更新 loading 状态为 false
                });
        } else {

        }
    }, [id]); // 空依赖项数组表示这个 useEffect 只会运行一次


    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.name === "") {
            setErrors('Name is Required');
        }
        else {
            setErrors('');


            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Bearer ${token}`, // 替換為你的 token
                'Content-Type': 'application/x-www-form-urlencoded',
            };
           //console.log(id);
            if (id) {

                axios.put(`${window.api_url}/factories/${id}`, formData, { headers },)
                    .then(response => {
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            window.location.href = "/factories";
                        });
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            // 如果是 401 错误，重定向到登录页面
                            window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                        } else {
                            console.error("獲取數據時出錯了！", error);
                        }
                    });
            } else {

                axios.post(`${window.api_url}/factories`, formData, { headers })
                    .then(response => {
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            window.location.href = "/factories";
                        });
                    })
                    .catch(error => {
                        // 處理錯誤
                        if (error.response && error.response.status === 401) {
                            // 如果是 401 错误，重定向到登录页面
                            window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                        } else {
                            console.error("獲取數據時出錯了！", error);
                        }
                    });
            }
        }
    };

    if (loading) {
        return Swal.showLoading(); // 显示加载状态
    }
    Swal.close()
    if(window.userRole!=="1"){
        return (<><Forbidden></Forbidden></>);
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <title>Factory Form</title>
                </Helmet>
            </HelmetProvider>
            <Header />

            <Container style={{ 'backgroundColor': 'white', "padding": "30px" }} className="mt-5">
                <Row>
                    <h4 className="mt-3">Factory Form</h4>
                </Row>

                <Form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name='name' value={formData.name || ''} placeholder="Name" onChange={handleChange} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            Address
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="address" value={formData.address || ''} placeholder="Address" onChange={handleChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            Country
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="country" value={formData.country || ''} placeholder="Country" onChange={handleChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            Phone
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="phone" value={formData.phone || ''} placeholder="Phone" onChange={handleChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                        Subcontractor
                        </Form.Label>
                        <Col sm="10">
                            <Select
                                name='subcontractor'
                                options={options}
                                value={options.find(option => option.value === formData.subcontractor)} // 设置选中的值
                                onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'subcontractor' })}
                            />
                        </Col>
                    </Form.Group>
                    {errors && (
                        <Row>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <span style={{ color: 'red', fontSize: '12px' }}>{errors}</span>
                            </Col>
                        </Row>
                    )}
                    <div style={{ "textAlign": "right" }}>
                        <Button type="submit">Submit</Button>
                    </div>

                </Form>
            </Container>
        </>
    );

}

export default FactoriesForm