// src/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert } from "react-bootstrap";
import Logo from "./logo.webp";
import "./Login.css";
import Swal from 'sweetalert2'


const Login = () => {
  const [email, setInputEmail] = useState('');
  const [password, setInputPassword] = useState('');
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)

      const formData = new FormData();
      formData.append('email', email); // 添加文本字段
      formData.append('password', password); // 添加文件字段

      axios.post(`${window.api_url}/users/login`, formData, {
        'Content-Type': 'application/x-www-form-urlencoded',
      }).then((response) => {
        const token = response.data.data.accessJwt.token;
        localStorage.setItem('token', token); // 保存 JWT 到本地存储

        const headers = {
          'Authorization': `Bearer ${token}`, // 替換為你的 token
          'Content-Type': 'application/json',
        };

        axios.get(`${window.api_url}/user`, { headers })
          .then((userResponse) => {
           //console.log(userResponse);
            localStorage.setItem('username', userResponse.data.data.name);
            localStorage.setItem('userId', userResponse.data.data.id);
            if (userResponse.data.data.role == null) {
              Swal.fire("FAIL", "role is null", "error")
              return;
            }
            localStorage.setItem('userRole', userResponse.data.data.role.id);
            // 重定向到主页或其他页面
            window.location.href = '/';
          });

      }).catch(error => {
        Swal.fire("FAIL", error.response.data.message, "error")
        setInputPassword("")
      });
    } catch (err) {
      setError('Login Fail');
    }
    setLoading(false);
  };


  return (
    <>
      <div
        className="sign-in__wrapper"
      >
        {/* Form */}
        <Form className="shadow p-4 bg-white rounded" onSubmit={handleLogin}>
          {/* Header */}
          <img
            className="img-thumbnail mx-auto d-block mb-2"
            src={Logo}
            alt="logo"
          />
          <div className="h4 mb-2 text-center">Please Log in</div>
          {/* ALert */}
          {show ? (
            <Alert
              className="mb-2"
              variant="danger"
              onClose={() => setShow(false)}
              dismissible
            >
              Incorrect eamil or password.
            </Alert>
          ) : (
            <div />
          )}
          <Form.Group className="mb-2" controlId="email">
            <Form.Label>Eamil</Form.Label>
            <Form.Control
              type="text"
              value={email}
              placeholder="email"
              onChange={(e) => setInputEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              placeholder="Password"
              onChange={(e) => setInputPassword(e.target.value)}
              required
            />
          </Form.Group>
          {/* <Form.Group className="mb-2" controlId="checkbox">
          <Form.Check type="checkbox" label="Remember me" />
        </Form.Group> */}
          {!loading ? (
            <Button className="w-100" variant="primary" type="submit">
              Login
            </Button>
          ) : (
            <Button className="w-100" variant="primary" type="submit" disabled>
              Logging In...
            </Button>
          )}
          {error && <p className='error-msg'>{error}</p>}
        </Form>
        {/* Footer */}
      </div>
    </>
  );
};

export default Login;
