import React, { useState, useEffect } from 'react';
import '../config/config.js';
import Header from '../components/Header.js'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Col, Row } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2'
import { color } from 'chart.js/helpers';


const User = () => {


    const [options, setOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [loading, setLoading] = useState(true); // 初始状态为 true，表示正在加载数据
    const [errors, setErrors] = useState('');

    const [formData, setFormData] = useState({
        status: true,
        email: '',
        phone: '',
        name: '',
        line: [],
        role: '',
        password: '',
        confirmPassword: '',
        message: '',
    });
    const handleChange = (e, actionMeta) => {
        const { name, value, options, type, checked } = e.target || {};

        // 处理多选下拉菜单
        if (actionMeta && actionMeta.action === 'select-multi') {
            setFormData({
                ...formData,
                [actionMeta.name]: actionMeta.selectedOptions ? actionMeta.selectedOptions.map(option => option.value) : [],
            });

            // 处理单选按钮
        } else if (type === 'radio') {
           //console.log(name, value)
            setFormData({ ...formData, [name]: value });

            // 处理复选框
        } else if (type === 'checkbox') {
            if (name === "status") {
                const newStatus = checked;
                setFormData({
                    ...formData,
                    [name]: checked ? 1 : 0,
                    ['message']: checked ? 'Enable' : 'Disable'
                });
               //console.log(formData);
            } else {
                setFormData({ ...formData, [name]: checked });
            }

            // 处理文本输入字段
        } else if (type === 'text' || type === 'email' || type === 'number' || type === 'password') {
            setFormData({ ...formData, [name]: value });

            // 处理选择框
        } else if (type === 'select-multiple') {
            setFormData({
                ...formData,
                [name]: Array.from(options).filter(option => option.selected).map(option => option.value),
            });
        }
    };


    useEffect(() => {
        if (errors) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: errors,
            });
        }
    }, [errors]);

    useEffect(() => {

        const token = localStorage.getItem('token');
        const headers = {
            'Authorization': `Bearer ${token}`, // 替換為你的 token
            'Content-Type': 'application/json',
        };

        // 使用 Promise.all 处理多个请求
        Promise.all([
            axios.get(`${window.api_url}/user`, { headers, }),
            axios.get(`${window.api_url}/lines`, { headers }),  // 第一个请求
            axios.get(`${window.api_url}/user-role`, { headers })  // 第二个请求
        ])
            .then(([formResponse, linesResponse, userRoleResponse]) => {
                if (formResponse.data.code === "0000") {
                   //console.log(formResponse.data.data)
                    formResponse.data.data.password = '';
                    formResponse.data.data.role = formResponse.data.data.role.id;
                    formResponse.data.data.confirmPassword = '';
                    formResponse.data.data.line = [];
                    formResponse.data.data.user_line_links.map((item) => {
                        formResponse.data.data.line.push(item.line.id)
                    })
                    if (formResponse.data.data.status == 1) {
                        formResponse.data.data.message = 'Enable';
                    } else {
                        formResponse.data.data.message = 'Disable';
                    }
                   //console.log(formResponse.data.data);
                    setFormData(formResponse.data.data);
                }

                if (linesResponse.data.code === "0000") {
                    const formattedOptions = linesResponse.data.data.map(line => ({
                        value: line.id,
                        label: line.name,
                    }));
                    setOptions(formattedOptions);
                }

                if (userRoleResponse.data.code === "0000") {
                    setTypeOptions(userRoleResponse.data.data);
                }

                setLoading(false); // 两个请求都完成后，更新 loading 状态为 false
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // 如果是 401 错误，重定向到登录页面
                    window.location.href = '/login';  // 直接使用相对路径 '/login'
                } else {
                    console.error("獲取數據時出錯了！", error);
                }
                console.error("获取数据时出错了！", error);
                setLoading(false); // 请求出错，更新 loading 状态为 false
            });

    }, []); // 空依赖项数组表示这个 useEffect 只会运行一次

    const handleMultiSelectChange = (selectedOptions) => {
        setFormData({
            ...formData,
            line: selectedOptions ? selectedOptions.map(option => option.value) : [],
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
       //console.log(formData.password)
       //console.log(formData.confirmPassword)
        if (formData.password !== formData.confirmPassword) {
            setErrors('Passwords do not match');
        }
        else if (formData.email === "") {
            setErrors('Eamil is Required');
        }
        else if (formData.name === "") {
            setErrors('Name is Required');
        } else {
            setErrors('');

            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Bearer ${token}`, // 替換為你的 token
                'Content-Type': 'application/x-www-form-urlencoded',
            };

            const id = localStorage.getItem('userId');

            axios.put(`${window.api_url}/user`, formData, { headers },)
                .then(response => {
                    Swal.fire({
                        icon: "success",
                        title: "Your work has been saved",
                        showConfirmButton: false,
                    });
                    
                    axios.get(`${window.api_url}/user`,{headers})
                    .then((userResponse) => {
                    localStorage.setItem('username', userResponse.data.data.name);
                    });
                })
                .catch(error => {
                    // 處理錯誤
                    if (error.response && error.response.status === 401) {
                        // 如果是 401 错误，重定向到登录页面
                        window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                    } else {
                        console.error("獲取數據時出錯了！", error);
                    }
                });
        }
    };

    if (loading) {
        return Swal.showLoading(); // 显示加载状态
    }
    Swal.close()
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <title>Edit User</title>
                </Helmet>
            </HelmetProvider>
            <Header />

            <Container style={{ 'backgroundColor': 'white', "padding": "30px" }} className="mt-5">
                <Row>
                    <h4 className="mt-3">Edit User</h4>
                </Row>

                <Form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Email
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" disabled name='email' value={formData.email || ''} placeholder="Email" onChange={handleChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="name" value={formData.name || ''} placeholder="Name" onChange={handleChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            Phone Number
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="phone" value={formData.phone || ''} placeholder="Phone Number" onChange={handleChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            Password
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="password" name="password" placeholder="Password" onChange={handleChange} />
                            <span className="ps" style={{ color: "red", fontSize: "12px" }}>If not modified, leave blank</span>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Confirm Password
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="password"
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                value={formData.confirmPassword || ''}
                                onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>

                    {errors && (
                        <Row>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <span style={{ color: 'red', fontSize: '12px' }}>{errors}</span>
                            </Col>
                        </Row>
                    )}
                    <div style={{ "textAlign": "right" }}>
                        <Button type="submit">Submit</Button>
                    </div>

                </Form>
            </Container>
        </>
    );

}

export default User