
import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import { Badge, Button } from "react-bootstrap";
import FilterComponent from "./FilterComponent";


const UserTable = props => {


  const columns = [
    {
      name: <strong style={{ fontSize: '18px', textAlign: "center", width: "100%" }}>Site</strong>,
      selector: row => row.site,
      sortable: true,
    },
    {
      name: <strong style={{ fontSize: '18px', textAlign: "center", width: "100%" }}>Type</strong>,
      selector: row => row.role ? row.role.name : "",
      sortable: true,
    },
    {
      name: <strong style={{ fontSize: '18px', textAlign: "center", width: "100%" }}>Name</strong>,
      selector: row => row.name ? row.name : "",
      sortable: true,
    },
    {
      name: <strong style={{ fontSize: '18px', textAlign: "center", width: "100%" }}>Email</strong>,
      selector: row => row.email ? row.email : "",
      sortable: true,
    },
    {
      name: <strong style={{ fontSize: '18px', textAlign: "center", width: "100%" }}>Phone Number</strong>,
      selector: row => row.phone ? row.phone : "",
      sortable: true,
    },
    {
      name: <strong style={{ fontSize: '18px', textAlign: "center", width: "100%" }}>Status</strong>,
      selector: row => row.statusValue, // 使用一个原始值来进行排序
      cell: row => (
        <div style={{ width: '100%', textAlign: "center" }}>
          {row.status === 1 ? <Badge bg="success">Enable</Badge> : <Badge bg="danger">Disable</Badge>}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong style={{ fontSize: '18px', textAlign: "center", width: "100%" }}>Action</strong>,
      selector: row => row.action,
    },
  ];

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', selectedRows);
  };
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'ALL',
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  const filteredItems = props.data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );


  const handlePageChange = () => {
    // 这里添加切换页面的逻辑
    window.location.href = "/users/create";
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <FilterComponent
          onFilter={e => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
        <Button onClick={handlePageChange} style={{ marginLeft: '10px' }}>
          Create New
        </Button>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <DataTable
        pagination
        paginationPerPage={10}
        paginationComponentOptions={paginationComponentOptions}
        paginationRowsPerPageOptions={[10, 20, 30]}
        highlightOnHover
        // selectableRows
        columns={columns}
        data={filteredItems}
        onSelectedRowsChange={handleChange}
        subHeader
        subHeaderComponent={subHeaderComponent}
      />
    </>
  );
}

export default UserTable;