import React from "react";
import '../config/config.js';
import Header from '../components/Header.js'
import { Button, Nav, Col, Tab, Row, FormSelect } from "react-bootstrap";
import { FaSoun, FaCloudDownloadAlt, FaVolumeOff } from "react-icons/fa";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PoTable from '../components/PoTable.js';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { FaVolumeHigh, FaVolumeXmark } from "react-icons/fa6";

const Settings = () => {

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <title>Dynamic Page title here</title>
                </Helmet>
            </HelmetProvider>
            <Header />
            <Container style={{ 'backgroundColor': 'white', "padding": "30px" }} className="mt-5">
            <Row >
                <Col md={3}>
                    <div>Site:</div>
                    <Form.Select aria-label="Default select example">
                        <option value="1">All</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
                </Col>
                <Col dm={5}>
                    <div>Period:</div>
                    <Form.Select aria-label="Default select example">
                        <option value="1">All</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
                </Col>
                <Col md={4}>

                    <Row className="mt-3">
                        <Col >
                            <Button className="btn-secondary"><FaVolumeXmark></FaVolumeXmark><br />Sound off</Button>&nbsp;
                            <Button><FaVolumeHigh></FaVolumeHigh><br />Sound on</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={3} sm={12} style={{ padding: "10px" }}>
                    <Col style={{ border: "1px solid #000000", padding: "10px", "background-color": "#EEEEEE", height: "100%" }}>
                        <Row className="mt-2">
                            <h4>Yield Alerts (%)</h4>
                        </Row>
                        <Row className="mt-1" style={{ padding: "10px" }}>
                            <Col md={8} style={{ padding: "10px" }}>Tester Jig</Col>
                            <Col md={4} style={{ "textAlign": 'center', "padding": "10px", "background-color": "rgb(100, 100, 100)", color: "#FFFFFF" }}>
                                98%
                            </Col>
                        </Row>
                        <Row className="mt-1" style={{ padding: "10px" }}>
                            <Col md={8} style={{ padding: "10px" }}>Assembly</Col>
                            <Col md={4} style={{ "textAlign": 'center', "padding": "10px", "background-color": "rgb(100, 100, 100)", color: "#FFFFFF" }}>
                                98%
                            </Col>
                        </Row>
                        <Row className="mt-1 mb-1" style={{ padding: "10px" }}>
                            <Col md={8} style={{ padding: "10px" }}>QC Jig</Col>
                            <Col md={4} style={{ "textAlign": 'center', "padding": "10px", "background-color": "rgb(100, 100, 100)", color: "#FFFFFF" }}>
                                98%
                            </Col>
                        </Row>
                    </Col>
                </Col>
                <Col md={5} style={{ padding: "10px" }}>
                    <Col style={{ border: "1px solid #000000", padding: "10px", "background-color": "#EEEEEE", height: "100%" }}>
                        <Row className="mt-2">
                            <h4>Yield Alerts (%)</h4>
                        </Row>
                        <Row className="mt-1" style={{ padding: "10px" }}>
                            <Col md={8} style={{ padding: "10px" }}>Tester Jig to Assembly</Col>
                            <Col md={4} style={{ "textAlign": 'center', "padding": "10px", "background-color": "rgb(100, 100, 100)", color: "#FFFFFF" }}>
                                48 hrs
                            </Col>
                        </Row>
                        <Row className="mt-1" style={{ padding: "10px" }}>
                            <Col md={8} style={{ padding: "10px" }}>Assembly to QC Jig</Col>
                            <Col md={4} style={{ "textAlign": 'center', "padding": "10px", "background-color": "rgb(100, 100, 100)", color: "#FFFFFF" }}>
                                72 hrs
                            </Col>
                        </Row>
                        <Row className="mt-1" style={{ padding: "10px" }}>
                            <Col md={8} style={{ padding: "10px" }}>QC Jig to Packaging</Col>
                            <Col md={4} style={{ "textAlign": 'center', "padding": "10px", "background-color": "rgb(100, 100, 100)", color: "#FFFFFF" }}>
                                48 hrs
                            </Col>
                        </Row>
                        <Row className="mt-1 mb-1" style={{ padding: "10px" }}>
                            <Col md={8} style={{ padding: "10px" }}>Packing to Packed</Col>
                            <Col md={4} style={{ "textAlign": 'center', "padding": "10px", "background-color": "rgb(100, 100, 100)", color: "#FFFFFF" }}>
                                24 hrs
                            </Col>
                        </Row>
                    </Col>
                </Col>

                <Col md={4} style={{ padding: "10px", textAlign: "justify" }}>
                    <Col style={{ border: "1px solid #000000", padding: "10px", "background-color": "#EEEEEE", height: "100%" }}>
                        <h4>Notes:</h4>
                        Foxconn 1, Foxconn2 etc.Period: last 24H, Last 7 days, Last 30 days, Custom Event Log when X time passed from device finishing a station and not received in next station.
                    </Col>
                </Col>
            </Row>
            <Row className="mt-1" style={{ padding: "10px" }}>
                <Col md={12}>
                    <Row style={{ border: "1px solid #000000", "background-color": "#EEEEEE" }}>
                        <Col style={{ padding: "10px" }}>
                            <h4>Send me Notifications by:</h4>
                            <Form.Check
                                inline
                                label="Email"
                                name="SendType"
                                type='checkbox'
                                id={`inline-check-1`}
                            />
                            <br />
                            <Form.Check
                                inline
                                label="SNS"
                                name="SendType"
                                type='checkbox'
                                id={`inline-check-2`}
                            />
                        </Col>
                        <Col style={{ padding: "10px" }}>
                            <h4>Users to Notify: </h4>
                            <Form.Select name='site' >
                                <option>Select Site</option>
                                <option value="FTC1">FTC1</option>
                                <option value="FTC2">FTC2</option>
                                <option value="ISR1">ISR1</option>
                            </Form.Select>
                        </Col>
                        <Col style={{ padding: "10px" }}>
                            <h4>Add External Email: </h4>
                            <Form.Select name='site' >
                                <option>Select Site</option>
                                <option value="FTC1">FTC1</option>
                                <option value="FTC2">FTC2</option>
                                <option value="ISR1">ISR1</option>
                            </Form.Select>
                        </Col>
                    </Row>
                </Col>
            </Row>
            </Container>
        </>
    );

}

export default Settings