import React, { useState, useEffect } from "react";
import '../../config/config.js';
import Header from '../../components/Header.js'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Row } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import StationsTable from '../../components/StationsTable.js';
import Forbidden from "../../components/Forbidden.js";

const token = localStorage.getItem('token');
const headers = {
    'Authorization': `Bearer ${token}`, // 替換為你的 token
    'Content-Type': 'application/json',
};

// const onClickDelete = (e) => {

//     Swal.fire({
//         title: "Are you sure?",
//         text: "You won't be able to revert this!",
//         icon: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#3085d6",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Yes, delete it!"
//     }).then((result) => {
//         if (result.isConfirmed) {

//             axios.delete(`${window.api_url}/stations/${e.target.getAttribute("sid")}`, { headers, })
//                 .then((response) => {
//                     if (response.data.code === "0000") {
//                         Swal.fire({
//                             title: "Deleted!",
//                             text: "Your file has been deleted.",
//                             icon: "success",
//                             timer: 3000
//                         }).then(()=>{
//                             window.location.reload();
//                         });
//                     }

//                 })
//                 .catch(error => {
//                     if (error.response && error.response.status === 401) {
//                         // 如果是 401 错误，重定向到登录页面
//                         window.location.href = '/login';  // 直接使用相对路径 '/login'
//                     } else {
//                         console.error("獲取數據時出錯了！", error);
//                     }
//                 });

//         }
//     });

// }



const List = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // 初始状态为 true，表示正在加载数据

    useEffect(() => {
        // 發送帶有 headers 的請求
        axios.get(`${window.api_url}/stations?page=0&size=100`, { headers })
            .then((response) => {
                if (response.data.code === "0000") {

                    if (response.data.data != null && response.data.data.content.length > 0) {
                        var rows = response.data.data.content;
                        rows.map((row) => {
                            row.action = <>
                                <a className="btn btn-sm btn-link" href={`/stations/${row.id}/edit`}>Edit</a>
                                {/*  | <button className="btn btn-sm btn-link" type="button" onClick={onClickDelete} sid={row.id}>Delete</button></> */}
                            </>
                            return null;
                        })
                        setData(rows);
                    }
                    setLoading(false)

                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // 如果是 401 错误，重定向到登录页面
                    window.location.href = '/login';  // 直接使用相对路径 '/login'
                } else {
                    console.error("獲取數據時出錯了！", error);
                }
            });

    }, []);

    if (loading) {
        return Swal.showLoading();
    }
    Swal.close()

    if (window.userRole !== "1") {
        return (<><Forbidden></Forbidden></>);
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <title>Stations Table</title>
                </Helmet>
            </HelmetProvider>
            <Header />

            <Row>
                <h4>Stations Table</h4>
            </Row>
            <StationsTable data={data} />

        </>
    );

}


export default List