import React, { useState, useEffect } from 'react';
import '../../config/config.js';
import Header from '../../components/Header.js'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Col, Row } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2'
import Forbidden from '../../components/Forbidden.js';


const UserForm = () => {
    const { id } = useParams();

    const [options, setOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [siteOptions, setSiteOptions] = useState([]);
    const [loading, setLoading] = useState(true); // 初始状态为 true，表示正在加载数据
    const [errors, setErrors] = useState('');
    const [isCreate, setIsCreate] = useState(false);
    const stationTypeOptions = {
        "board": "Board Tester",
        "assembly": "Assembly Station",
        "qc": "QC",
        "packed": "Packed"
    };

    // 定義一個驗證電子郵件的正則表達式
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const [formData, setFormData] = useState({
        status: 1,
        email: '',
        phone: '',
        name: '',
        line: [],
        site: [],
        role: "4",
        password: '',
        confirmPassword: '',
        message: '',
        station_type: '',
        id: id
    });
    const handleChange = (e, actionMeta) => {
        const { name, value, options, type, checked } = e.target || {};

        // 处理多选下拉菜单
        if (actionMeta && actionMeta.action === 'select-multi') {
            setFormData({
                ...formData,
                [actionMeta.name]: actionMeta.selectedOptions ? actionMeta.selectedOptions.map(option => option.value) : [],
            });

            // 处理单选按钮
        } else if (type === 'radio') {
           //console.log(name, value)
            setFormData({ ...formData, [name]: value });

            // 处理复选框
        } else if (type === 'checkbox') {
            if (name === "status") {
                setFormData({
                    ...formData,
                    [name]: checked ? 1 : 0,
                    ['message']: checked ? 'Enable' : 'Disable'
                });
               //console.log(formData);
            } else {
                setFormData({ ...formData, [name]: checked });
            }

            // 处理文本输入字段
        } else if (type === 'text' || type === 'email' || type === 'number' || type === 'password') {
            setFormData({ ...formData, [name]: value });

            // 处理选择框
        } else if (type === 'select-multiple') {
            setFormData({
                ...formData,
                [name]: Array.from(options).filter(option => option.selected).map(option => option.value),
            });
        }
    };


    useEffect(() => {
        if (errors) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: errors,
            });
        }
    }, [errors]);

    useEffect(() => {

        if (id) {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Bearer ${token}`, // 替換為你的 token
                'Content-Type': 'application/json',
            };

            // 使用 Promise.all 处理多个请求
            Promise.all([
                axios.get(`${window.api_url}/users/${id}`, { headers, }),
                axios.get(`${window.api_url}/lines`, { headers }),  // 第一个请求
                axios.get(`${window.api_url}/user-role`, { headers }),  // 第二个请求
                axios.get(`${window.api_url}/factories`, { headers }),  
            ])
                .then(([formResponse, linesResponse, userRoleResponse, factoriesResponse]) => {
                    if (formResponse.data.code === "0000") {
                   
                        formResponse.data.data.password = '';
                        if (formResponse.data.data.role != null) {
                            formResponse.data.data.role = formResponse.data.data.role.id + "";
                        }
                        formResponse.data.data.confirmPassword = '';
                        formResponse.data.data.line = [];
                        formResponse.data.data.site = [];

                        if (formResponse.data.data.user_line_links != null) {
                            formResponse.data.data.user_line_links.map((item) => {
                                formResponse.data.data.line.push(item.line.id)
                                return item;
                            })
                        }
                        if (formResponse.data.data.user_factory_link != null) {
                            formResponse.data.data.user_factory_link.map((item) => {
                                formResponse.data.data.site.push(item.factory.id)
                                return item;
                            })
                        }

                        
                        if (formResponse.data.data.status === 1) {
                            formResponse.data.data.message = 'Enable';
                        } else {
                            formResponse.data.data.message = 'Disable';
                        }
                       //console.log(formResponse.data.data);
                        setFormData(formResponse.data.data);
                    }

                    if (linesResponse.data.code === "0000") {
                        const formattedOptions = linesResponse.data.data.map(line => ({
                            value: line.id,
                            label: `${line.factory.name}-${line.name}`,
                        }));
                        setOptions(formattedOptions);
                    }

                    if (userRoleResponse.data.code === "0000") {
                        setTypeOptions(userRoleResponse.data.data);
                    }

                    if (factoriesResponse.data.code === "0000") {
                        const formattedOptions = factoriesResponse.data.data.content.map(site => ({
                            value: site.id,
                            label: `${site.name}`,
                        }));
                        setSiteOptions(formattedOptions);
                    }

                    

                    setLoading(false); // 两个请求都完成后，更新 loading 状态为 false
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        // 如果是 401 错误，重定向到登录页面
                        window.location.href = '/login';  // 直接使用相对路径 '/login'
                    } else {
                        console.error("獲取數據時出錯了！", error);
                    }
                    console.error("获取数据时出错了！", error);
                    setLoading(false); // 请求出错，更新 loading 状态为 false
                });
        } else {
            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Bearer ${token}`, // 替換為你的 token
                'Content-Type': 'application/json',
            };

            // 使用 Promise.all 处理多个请求
            Promise.all([
                axios.get(`${window.api_url}/lines`, { headers }),  // 第一个请求
                axios.get(`${window.api_url}/user-role`, { headers }),  // 第二个请求
                axios.get(`${window.api_url}/factories`, { headers }),  
            ])
                .then(([linesResponse, userRoleResponse,factoriesResponse]) => {
                    if (linesResponse.data.code === "0000") {
                        const formattedOptions = linesResponse.data.data.map(line => ({
                            value: line.id,
                            label: `${line.factory.name} - `+line.name,
                        }));
                        setOptions(formattedOptions);
                    }
                    if (userRoleResponse.data.code === "0000") {
                        setTypeOptions(userRoleResponse.data.data);
                    }
                    
                    if (factoriesResponse.data.code === "0000") {
                        const formattedOptions = factoriesResponse.data.data.content.map(site => ({
                            value: site.id,
                            label: `${site.name}`,
                        }));
                        setSiteOptions(formattedOptions);
                    }

                    setLoading(false); // 两个请求都完成后，更新 loading 状态为 false
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        // 如果是 401 错误，重定向到登录页面
                        window.location.href = '/login';  // 直接使用相对路径 '/login'
                    } else {
                        console.error("獲取數據時出錯了！", error);
                    }
                    console.error("获取数据时出错了！", error);
                    setLoading(false); // 请求出错，更新 loading 状态为 false
                });

            setIsCreate(true);
        }
    }, [id]); // 空依赖项数组表示这个 useEffect 只会运行一次

    const handleMultiSelectChange = (selectedOptions) => {
        setFormData({
            ...formData,
            line: selectedOptions ? selectedOptions.map(option => option.value) : [],
        });
    };
    const handleSiteMultiSelectChange = (selectedOptions) => {
        setFormData({
            ...formData,
            site: selectedOptions ? selectedOptions.map(option => option.value) : [],
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
       //console.log(formData.password)
       //console.log(formData.confirmPassword)
        if (formData.password !== formData.confirmPassword) {
            setErrors('Passwords do not match');
        }
        else if (formData.email === "") {
            setErrors('Eamil is Required');
        } else if (!validateEmail(formData.email)) {
            setErrors('Invalid email address');
        }
        else if (formData.name === "") {
            setErrors('Name is Required');
        }
        else if (!id && formData.password === "") {
            setErrors('Password is Required');
        }
        else {
            setErrors('');


            const token = localStorage.getItem('token');
            const headers = {
                'Authorization': `Bearer ${token}`, // 替換為你的 token
                'Content-Type': 'application/x-www-form-urlencoded',
            };

            if (id) {

                axios.put(`${window.api_url}/users/${id}`, formData, { headers },)
                    .then(response => {
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            window.location.href = "/users";
                        });
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            // 如果是 401 错误，重定向到登录页面
                            window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                        } else {
                            console.error("獲取數據時出錯了！", error);
                        }
                    });
            } else {
                axios.post(`${window.api_url}/users`, formData, { headers })
                    .then(response => {
                        Swal.fire({
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        }).then(() => {
                            window.location.href = "/users";
                        });
                    })
                    .catch(error => {
                        // 處理錯誤
                        if (error.response && error.response.status === 401) {
                            // 如果是 401 错误，重定向到登录页面
                            window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                        } else {
                            console.error("獲取數據時出錯了！", error);
                        }
                    });
            }
        }
    };

    if (loading) {
        return Swal.showLoading(); // 显示加载状态
    }
    Swal.close()
    if (window.userRole > 3) {
        return (<><Forbidden></Forbidden></>);
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <title>User From</title>
                </Helmet>
            </HelmetProvider>
            <Header />

            <Container style={{ 'backgroundColor': 'white', "padding": "30px" }} className="mt-5">
                <Row>
                    <h4 className="mt-3">User From</h4>
                </Row>

                <Form className="mt-4" onSubmit={handleSubmit}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Status
                        </Form.Label>
                        <Col sm="10">
                            <Form.Check
                                type="switch"
                                id="status-switch"
                                name="status"
                                label={formData.message}
                                checked={formData.status}
                                onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Email <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="email" required name='email' autoComplete='false' value={formData.email || ''} placeholder="Email" onChange={handleChange} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            Password
                            {isCreate && (
                                <span style={{ color: "red" }}>*</span>
                            )}
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="password"
                                required={isCreate} name="password" autoComplete='false' placeholder="Password" onChange={handleChange} />
                            <span className="ps" style={{ color: "red", fontSize: "12px" }}>If not modified, leave blank</span>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">
                            Confirm Password
                            {isCreate && (
                                <span style={{ color: "red" }}>*</span>
                            )}
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control
                                required={isCreate}
                                type="password"
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                value={formData.confirmPassword || ''}
                                onChange={handleChange}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" required name="name" value={formData.name || ''} placeholder="Name" onChange={handleChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            Phone Number
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" name="phone" value={formData.phone || ''} placeholder="Phone Number" onChange={handleChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm="2">
                            Type
                        </Form.Label>
                        <Col sm="10">
                            {typeOptions.map((option, index) => (
                                (window.userRole < option.id || window.userRole == 1) && (
                                    <Form.Check
                                        key={index}
                                        inline
                                        label={option.name}
                                        name="role"
                                        type='radio'
                                        id={`inline-radio-${index}`}
                                        value={option.id}
                                        checked={"" + formData.role === "" + option.id}
                                        onChange={handleChange}
                                    />
                                )
                            ))}
                            <br />
                            <span className="ps" style={{ color: "#666666", fontSize: "12px" }}>
                                PS : {typeOptions.find(item => item.id === parseInt(formData.role))?.description || ''}
                            </span>
                        </Col>
                    </Form.Group>


                    {/* 根據選中的 role 值來顯示或隱藏 Line 表單 */}
                    {formData.role === '2'  && (
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">
                                Site
                                {isCreate && (
                                    <span style={{ color: "red" }}>*</span>
                                )}
                            </Form.Label>
                            <Col sm="10">
                                <Select
                                    required
                                    isMulti
                                    options={siteOptions}
                                    onChange={handleSiteMultiSelectChange}
                                    value={siteOptions.filter(option => formData.site?.includes(option.value))}  // 使用 optional chaining
                                />
                            </Col>
                        </Form.Group>
                    )}


                    {/* 根據選中的 role 值來顯示或隱藏 Line 表單 */}
                    {(formData.role === '3' || formData.role === '4') && (
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">
                                Line
                                {isCreate && (
                                    <span style={{ color: "red" }}>*</span>
                                )}
                            </Form.Label>
                            <Col sm="10">
                                <Select
                                    required
                                    isMulti
                                    options={options}
                                    onChange={handleMultiSelectChange}
                                    value={options.filter(option => formData.line?.includes(option.value))}  // 使用 optional chaining
                                />
                            </Col>
                        </Form.Group>
                    )}

                    {/* 根據選中的 role 值來顯示或隱藏 Line 表單 */}
                    {(formData.role === '4') && (

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">
                                Station
                                {isCreate && (
                                    <span style={{ color: "red" }}>*</span>
                                )}
                            </Form.Label>
                            <Col sm="10">
                                {Object.entries(stationTypeOptions).map(([key, value], index) => (
                                    <Form.Check
                                        required
                                        inline
                                        label={value}  // 只顯示值，即 "Board Tester", "Assy station" 等等
                                        name="station_type"
                                        type='radio'
                                        id={`st-inline-radio-${index}`}
                                        value={key}  // 使用 key 作為 value，例如 "board", "assy" 等等
                                        checked={formData.station_type === key}
                                        onChange={handleChange}
                                    />
                                ))}
                            </Col>
                        </Form.Group>
                    )}
                    {errors && (
                        <Row>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <span style={{ color: 'red', fontSize: '12px' }}>{errors}</span>
                            </Col>
                        </Row>
                    )}
                    <div style={{ "textAlign": "right" }}>
                        <Button type="submit">Submit</Button>
                    </div>

                </Form>
            </Container>
        </>
    );

}

export default UserForm