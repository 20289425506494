import React, { useState, useEffect, useRef } from "react";
import '../config/config.js';
import Header from '../components/Header.js'
import { Button, Col, Nav, Pagination, Row, Tab } from "react-bootstrap";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Form from 'react-bootstrap/Form';
import { ChartBar } from "../components/ChartBar.js";
import { ChartLine } from "../components/ChartLine.js";
import { ChartPie } from "../components/ChartPie.js";
import queryString from "query-string";
import Container from 'react-bootstrap/Container';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from "sweetalert2";

import { Bar, Line, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { TfiControlShuffle } from "react-icons/tfi";
import axios from "axios";
import { differenceInDays } from 'date-fns';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineController,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LineController,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
);



const Analytics = () => {
    const parsedHash = queryString.parse(window.location.search);
    const [factory, setFactories] = useState(parsedHash.factory || 'all');
    const [station, setStation] = useState(parsedHash.station || 'all');
    const [stationName, setStationName] = useState(parsedHash.station || 'All');
    const [day, setDay] = useState(parsedHash.station || '1');
    const [factoriesBtn, setFactoriessButtons] = useState([]);
    const [activeKey, setActiveKey] = useState('Yield');
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const chartRef = useRef(null);
    const [isInitialLoad, setIsInitialLoad] = useState(false); // 添加初始加载标志

    const [opfIsLoading, setOpfIsLoading] = useState(false); // 添加初始加载标志
    const [yiIsLoading, setYiIsLoading] = useState(false); // 添加初始加载标志
    const [treIsLoading, setTreIsLoading] = useState(false); // 添加初始加载标志


    const opfAbortControllerRef = useRef(null); // 用于存储当前的 AbortController 实例
    const yiAbortControllerRef = useRef(null); // 用于存储当前的 AbortController 实例
    const treAbortControllerRef = useRef(null); // 用于存储当前的 AbortController 实例

    const token = localStorage.getItem('token');
    const headers = {
        'Authorization': `Bearer ${token}`, // 替換為你的 token
        'Content-Type': 'application/json',
    };


    const [barData, setBarData] = useState({ labels: [], datasets: [] });
    const [lineData, setLineData] = useState({ labels: [], datasets: [] });
    const [trendData, setTrendData] = useState([
        { labels: [], datasets: [] }
    ]);
    const [trendOptions, setTrendOptions] = useState({
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: 'All Yield vs Volume',
                font: {
                    size: 24
                }
            }, 
            datalabels: {  // 仅格式化 y1 轴的数据
                align: "right",
                formatter: function (value, context) {
                    if (context.dataset.yAxisID === 'y1') {
                        return Math.floor(value) + '%';  // 格式化 y1 轴数据为百分比
                    } else {
                        return value;  // 其他轴保留原始数据
                    }
                },
                backgroundColor: function (context) {
                    if (context.dataset.yAxisID === 'y1') {
                        return 'rgba(238, 137, 7, 0.8)';  // y1 轴标签背景色
                    } else {
                        return 'rgba(0, 0, 0, 0.5)';  // y 轴标签背景色
                    }
                },
                borderRadius: 4,  // 标签边框圆角
                color: function (context) {
                    if (context.dataset.yAxisID === 'y1') {
                        return 'white';  // y 标签文字颜色
                    } else {
                        return 'white';  // y标签文字颜色
                    }
                },
                padding: {
                    top: 3,
                    bottom: 3,
                    left: 5,
                    right: 5
                },
            },
            beforeDraw: function(chart) {
                const datasets = chart.data.datasets;
                datasets.forEach(dataset => {
                    // 过滤掉为 0 的数据点
                    dataset.data = dataset.data.map(value => value === 0 ? null : value);
                });
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                type: 'linear', // 确保这是线性类型
                stacked: false, // 禁止堆叠
            },
            y1: {
                type: 'linear',
                position: 'right',
                max: 100, // 設置 B 軸的最大值為 100
                ticks: {
                    callback: function (value) {
                        return value + '%'; // B圖表的單位顯示百分比
                    }
                },
                grid: {
                    drawOnChartArea: false // 防止右側的網格線干擾左側
                }
            }
        },

    });



    const options = {
        plugins: {
            legend: {
                labels: {
                    filter: function (legendItem, chartData) {
                        // 過濾掉所有數據為0的項目，不顯示其圖例標籤
                        const datasetIndex = legendItem.datasetIndex;
                        const data = chartData.datasets[datasetIndex].data;
                        //console.log(data)

                        return !data.every(value => value === 0);
                    }
                }
            },
            datalabels: {
                anchor: function (context) {
                    const index = context.datasetIndex;
                    return index === 0 ? 'end' : 'end';  // 控制 PASS 和 FAIL 标签的位置锚点为柱的末端
                },
                align: function (context) {
                    const index = context.datasetIndex;
                    return index === 0 ? 'start' : 'end';  // PASS 的标签显示在柱的顶部外部，FAIL 的标签显示在柱的底部外部
                },
                formatter: function (value, context) {
                    // 如果數值為0，則不顯示標籤
                    return value === 0 ? null : value;
                },
                backgroundColor: function (context) {
                    const index = context.datasetIndex;
                    return index === 0 ? 'rgba(26, 135, 135, 0.5)' : 'rgba(255, 99, 132, 0.5)';  // PASS 标签附着在柱的顶端，FAIL 标签附着在柱的底端
                },  // 标签的背景色
                borderRadius: 4,  // 标签边框圆角
                color: 'white',  // 标签文字颜色
                padding: {
                    top: 3,
                    bottom: 3,
                    left: 5,
                    right: 5
                },
                maxBarThickness: 50
            },
            title: {
                display: true,
                text: 'Overrall Pass/Fail',
                font: {
                    size: 24
                }
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    const line_options = {
        plugins: {
            legend: {
                position: 'bottom',
                display: false
            },
            title: {
                display: true,
                text: 'yield[%]',
                font: {
                    size: 24
                }
            },
            datalabels: {
                align: "top",
                formatter: function (value, context) {
                    return Math.floor(value) + '%';
                },
                backgroundColor: 'rgba(0, 0, 0, 0.5)',  // 标签的背景色
                borderRadius: 4,  // 标签边框圆角
                color: 'white',  // 标签文字颜色
                padding: {
                    top: 3,
                    bottom: 3,
                    left: 5,
                    right: 5
                },

            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                max: 100, // 設置 B 軸的最大值為 100
            },
        },

    }

    useEffect(() => {
        const fetchDataAsync = async () => {
            try {
                const response = await axios.get(`${window.api_url}/factories`, { headers });
                if (response.data.code === "0000") {
                    setFactoriessButtons(response.data.data.content);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // 如果是 401 错误，重定向到登录页面
                    window.location.href = 'login';  // 这里需要确保 '/login' 是你的登录页面路由
                } else {
                    console.error("獲取數據時出錯了！", error);
                }
            }

            // 假设 setFactories(factory) 是一个同步操作，不需要 await
            setFactories(factory);

            // 假设 fetchData(station) 是一个异步操作，使用 await
            await fetchData(station);

            const today = new Date();
            // Get the first day of the current month
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

            // Set the initial state for startDate and endDate
            setStartDate(firstDayOfMonth);
            setEndDate(today);
        };

        fetchDataAsync();

        if (opfAbortControllerRef.current) {
            opfAbortControllerRef.current.abort();
        }

        if (yiAbortControllerRef.current) {
            yiAbortControllerRef.current.abort();
        }
        if (treAbortControllerRef.current) {
            treAbortControllerRef.current.abort();
        }


    }, []); // 只在组件挂载时运行一次



    const handlefactoriesChange = (val) => {
        //console.log("handlefactoriesChange")
        setCurrentPage(1)
        setIsInitialLoad(false);
        setFactories(val);
    };
    const handleDayChange = (val) => {
        //console.log("handleDayChange")
        setCurrentPage(1)
        setIsInitialLoad(false);
        setDay(val);
    }
    const handleStationChange = async (val) => {
        //console.log("handleStationChange")
        let stationLabel;
        switch (val) {
            case "qc":
                stationLabel = "QC";
                break;
            case "board":
                stationLabel = "Board";
                break;
            case "all":
                stationLabel = "All";
                break;
            default:
                stationLabel = "";
        }
        // Update station name state
        // await setStationName(stationLabel);

        setTrendOptions(prevOptions => ({
            ...prevOptions, // Spread the previous options to retain unchanged settings
            plugins: {
                ...prevOptions.plugins, // Spread previous plugins settings
                title: {
                    ...prevOptions.plugins.title, // Spread previous title settings
                    text: `${stationLabel} Yield vs Volume`, // Update the title text with new station label
                },
            },
        }));

        await setCurrentPage(1)
        await setIsInitialLoad(false);
        setStation(val);

    };

    const handleSearchClick = async (val) => {
        console.log("handleSearchClick");
        await handleDayChange('');
        await handleDayChange('search');
      
    }

    const handleSelect = (selectedKey) => {
        setActiveKey(selectedKey);
    };
    const handlePageChange = (pageNumber) => {
        //console.log("page", pageNumber)
        setCurrentPage(pageNumber)
    };

    useEffect(() => {
        console.log("useEffect-1")
        if (isInitialLoad) {
            return; // 直接返回，不执行 fetchData
        }

        //console.log("isInitialLoad")

        if (day !== null && station !== null && factory !== null) {
            setIsInitialLoad(true);
            fetchData();
        }

    }, [day, station, factory]);

    useEffect(() => {
        //console.log("useEffect-2")
        const chart = chartRef.current;
        chart.update();
    }, [activeKey]);




    const fetchData = () => {
        console.log("fetchData");
        getBarData();
        generateRandomTrendsData();
        generateRandomLineData();
    };
    const getRd = (max) => {
        return parseInt(Math.random() * max);
    }

    const getBarData = async () => {
        try {
            var response;
            setOpfIsLoading(true);

            if (opfAbortControllerRef.current) {
                opfAbortControllerRef.current.abort();
            }
            // 创建新的 AbortController
            const controller = new AbortController();
            opfAbortControllerRef.current = controller;

            if (day == 'search') {
                if (startDate == null || endDate == null) {
                    Swal.fire("Start date and end date are required")
                    return;
                } else {
                    Swal.showLoading();
                    response = await axios.get(`${window.api_url}/analytics/pfo/${factory}/0?s=${startDate.getTime()}&e=${endDate.getTime()}&station=${station}`,
                        {
                            headers,
                            signal: controller.signal // 将 AbortController 的 signal 添加到请求中
                        });

                }
            } else {
                response = await axios.get(`${window.api_url}/analytics/pfo/${factory}/${day}?station=${station}`, { headers ,
                    signal: controller.signal // 将 AbortController 的 signal 添加到请求中
                });
            }

            console.log(response.data.data)

            setOpfIsLoading(false);
            setBarData({
                labels: response.data.data.labels,
                datasets: response.data.data.datasets
            })
            Swal.close()
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // 如果是 401 错误，重定向到登录页面
                window.location.href = '/login';  // 直接使用相对路径 '/login'
            } else if (axios.isCancel(error)) {
                console.log('Request was cancelled');
            } else {
                console.error("獲取數據時出錯了！", error);
            }
            setTreIsLoading(false);
            Swal.close()
        }
    };

    const generateRandomLineData = async () => {

        try {
            var response;
            setYiIsLoading(true);

            if (yiAbortControllerRef.current) {
                yiAbortControllerRef.current.abort();
            }
            // 创建新的 AbortController
            const controller = new AbortController();
            yiAbortControllerRef.current = controller;

            if (day == 'search') {
                if (startDate == null || endDate == null) {
                    Swal.fire("Start date and end date are required")
                    return;
                } else {
                    Swal.showLoading();
                    response = await axios.get(`${window.api_url}/analytics/yield/0?sDate=${startDate.getTime()}&eDate=${endDate.getTime()}&station=${station}&factory=${factory}`, { headers,
                        signal: controller.signal // 将 AbortController 的 signal 添加到请求中
                    });
                }
            } else {
                response = await axios.get(`${window.api_url}/analytics/yield/${day}?station=${station}&factory=${factory}`, { headers,
                    signal: controller.signal // 将 AbortController 的 signal 添加到请求中
                });
            }
            setYiIsLoading(false);
            setLineData({
                labels: response.data.data.labels,
                datasets: response.data.data.datasets
            })
            Swal.close()
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // 如果是 401 错误，重定向到登录页面
                window.location.href = '/login';  // 直接使用相对路径 '/login'
            } else if (axios.isCancel(error)) {
                console.log('Request was cancelled');
            } else {
                console.error("獲取數據時出錯了！", error);
            }
            setTreIsLoading(false);
            Swal.close()
        }
    };

    const generateRandomTrendsData = async () => {

        try {
            var response;
            setTreIsLoading(true);

            if (treAbortControllerRef.current) {
                treAbortControllerRef.current.abort();
            }
            // 创建新的 AbortController
            const controller = new AbortController();
            treAbortControllerRef.current = controller;

            console.log(day)
            if (day == 'search') {
                if (startDate == null || endDate == null) {
                    Swal.fire("Start date and end date are required")
                    return;
                } else {
                    Swal.showLoading();
                    response = await axios.get(`${window.api_url}/analytics/trends/0?sDate=${startDate.getTime()}&eDate=${endDate.getTime()}&station=${station}&factory=${factory}`, { headers,
                        signal: controller.signal // 将 AbortController 的 signal 添加到请求中
                    });
                }
            } else {
                response = await axios.get(`${window.api_url}/analytics/trends/${day}?station=${station}&factory=${factory}`, { headers ,
                    signal: controller.signal // 将 AbortController 的 signal 添加到请求中
                });
            }

            setTreIsLoading(false);
            if (response.data.data.length == 0) {
                setTrendData([
                    {
                        labels: [],
                        datasets: [],
                    }
                ])
            } else {
                setTrendData(response.data.data)
            }

            Swal.close()
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // 如果是 401 错误，重定向到登录页面
                window.location.href = '/login';  // 直接使用相对路径 '/login'
            } else if (axios.isCancel(error)) {
                console.log('Request was cancelled');
            } else {
                console.error("獲取數據時出錯了！", error);
            }
            setTreIsLoading(false);
            Swal.close()
        }
    };



    const Cheader = () => {
        return (
            <>
                <Row>
                    <Col className="col-md-8  mt-3">
                        <Row>
                            <Col className="mt-3">
                                <Row>
                                    <ToggleButtonGroup type="radio" name="factory" defaultValue={factory} onChange={handlefactoriesChange}>
                                        <ToggleButton className="factory-switch-btn" variant="outline-primary" id="tbg-radio-0" value='all' >All</ToggleButton>
                                        {factoriesBtn.map((button, index) => (
                                            <ToggleButton className="factory-switch-btn" variant="outline-primary" id={`tbg-radio-${index + 1}`} value={button.id} >{button.name}</ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="mt-3">
                                <Row>
                                    <ToggleButtonGroup type="radio" name="type" defaultValue={day} onChange={handleDayChange}>
                                        <ToggleButton className="datetime-switch-btn" variant="outline-primary" id="datetime-radio-1" style={{ "width": "100%" }} value='1'>Last 24 Hours</ToggleButton>
                                        <ToggleButton className="datetime-switch-btn" variant="outline-primary" id="datetime-radio-2" style={{ "width": "100%" }} value='7'>Last 7 Days</ToggleButton>
                                        <ToggleButton className="datetime-switch-btn" variant="outline-primary" id="datetime-radio-3" style={{ "width": "100%" }} value='30'>Last 30 Days</ToggleButton>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                placeholderText="Start Date"
                                                className="form-control"
                                                showMonthDropdown
                                                showYearDropdown
                                                dateFormat="yyyy-MM-dd" // 設定日期格式
                                                style={{ width: '150px' }}
                                            />
                                            <span style={{ lineHeight: '38px', margin: '0 10px' }}>~</span>
                                            <DatePicker
                                                selected={endDate}
                                                onChange={(date) => setEndDate(date)}
                                                placeholderText="End Date"
                                                className="form-control"
                                                showMonthDropdown
                                                showYearDropdown
                                                dateFormat="yyyy-MM-dd" // 設定日期格式
                                                style={{ width: '150px' }}
                                            />
                                        </div>
                                        <ToggleButton onClick={handleSearchClick} className="datetime-switch-btn" variant="outline-primary" id="datetime-radio-0" style={{ width: '20%', marginLeft: '1%' }} value='search'>Search</ToggleButton>
                                    </ToggleButtonGroup>
                                </Row>
                            </Col >
                        </Row >

                    </Col>
                    <Col className="col-md-4 mt-3  p-2" style={{ borderWidth: "1px", borderColor: "#000000", borderStyle: "solid" }}>
                        <Row><h4>Station</h4>
                        </Row>
                        <Row>
                            <ToggleButtonGroup type="radio" name="station" defaultValue={station} onChange={handleStationChange}>
                                <ToggleButton className="station-switch-btn" variant="outline-primary" id="tbgs-radio-5" value='all' >All</ToggleButton>
                                <ToggleButton className="station-switch-btn" variant="outline-primary" id="tbgs-radio-1" value='board' >Board</ToggleButton>
                                <ToggleButton className="station-switch-btn" variant="outline-primary" id="tbgs-radio-2" value='qc' >QC</ToggleButton>
                                <ToggleButton className="station-switch-btn" variant="outline-primary" id="tbgs-radio-3" value='assembly' >Assembly</ToggleButton>
                                <ToggleButton className="station-switch-btn" variant="outline-primary" id="tbgs-radio-4" value='packed' >Packed</ToggleButton>
                            </ToggleButtonGroup>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <div className="analytics">
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="" />
                    <title>Analytics</title>
                </Helmet>
            </HelmetProvider>
            <Header />
            <div style={{ backgroundColor: "#FFFFFF" }} className="p-4">
                <Cheader />
                <Row className="mt-3">
                    <Nav justify variant="tabs" defaultActiveKey="Yield" onSelect={handleSelect}>
                        <Nav.Item >
                            <Nav.Link eventKey="Yield">Overall Yield</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Trends">Trends</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Col className="tap-border">
                        <Row>
                            <Tab.Content>
                                <Tab.Pane eventKey="Yield" active={activeKey === 'Yield'}>
                                    <Row>
                                        <Col md={6} >
                                            <div style={{ position: "absolute", left: "5px", top: "5px", border: "1px #aaa solid", padding: "2px" }}>Units x1000</div>
                                            {opfIsLoading ? (
                                                // 如果正在加载，显示 Loading 画面
                                                <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                                                    <div className="spinner-border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                // 加载完成后显示 Chart.js 图表
                                                <Bar options={options} data={barData} plugins={[ChartDataLabels]} />
                                            )}
                                        </Col>
                                        <Col md={6}>
                                            {yiIsLoading ? (
                                                // 如果正在加载，显示 Loading 画面
                                                <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                                                    <div className="spinner-border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                // 加载完成后显示 Chart.js 图表
                                                <Bar options={line_options} data={lineData} plugins={[ChartDataLabels]} />
                                            )}
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Trends" active={activeKey === 'Trends'}>
                                    <div style={{ maxHeight: '500px' }}>
                                        {treIsLoading ? (
                                            // 如果正在加载，显示 Loading 画面
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            // 加载完成后显示 Chart.js 图表
                                            <Bar ref={chartRef}
                                                options={trendOptions} data={trendData[currentPage - 1]} plugins={[ChartDataLabels]} />
                                        )}
                                    </div>
                                    <Pagination className="pagination">
                                        {trendData.map((_, index) => (
                                            index + 1 === trendData.length ? null : (
                                                <Pagination.Item
                                                    key={index}
                                                    active={index + 1 === currentPage}
                                                    onClick={() => handlePageChange(index + 1)}
                                                >
                                                    {index + 1}
                                                </Pagination.Item>
                                            )
                                        ))}
                                        <Pagination.Item
                                            key={trendData.length}
                                            active={trendData.length === currentPage}
                                            onClick={() => handlePageChange(trendData.length)}
                                        >
                                            All
                                        </Pagination.Item>
                                    </Pagination>
                                </Tab.Pane>
                            </Tab.Content>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Analytics;
